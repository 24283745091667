import { Logger } from '@core';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AWSAuthService } from '@app/@core/services/aws/auth.service';

const log = new Logger('AuthGuard');


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  // This guard controls that users without authentication or with expired
  // authentication cannot access the application

  constructor(private router: Router, private awsAuthService: AWSAuthService) { }

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      const _user = await this.awsAuthService.currentAuthenticatedUser();
      log.debug('[AUTH] (authenticated) > Not action');

      return true;
    }
    catch (error) {
      log.debug('[AUTH] (not authenticated) Redirecting out app...', error);
      this.router.navigate(['/auth'], { queryParams: { redirect: state.url }, replaceUrl: true });

      return false;
    }
  }
}


@Injectable({
  providedIn: 'root'
})
export class AuthSessionGuard implements CanActivate {

  // This guard controls that authenticated users with session cannot access out
  // the application (requier logout operation)

  constructor(private router: Router, private awsAuthService: AWSAuthService) { }

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      const _user = await this.awsAuthService.currentAuthenticatedUser();

      log.debug('[SESSION] (authenticated) > Redirecting inside app...');
      this.router.navigate([''], { queryParams: { redirect: state.url }, replaceUrl: true });

      return false;
    }
    catch (error) {
      log.debug('[SESSION] (not authenticated) > Not action');

      return true;
    }
  }
}
