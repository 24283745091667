import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';


// Material default configurations
import { MaterialModule } from './material/material.module';
import { MATERIAL_SETTINGS } from './material/material.settings';

import { COMPONENTS } from './components';
import { DIRECTIVES } from './directives';
import { SERVICES } from './services';
import { SNIPPETS } from './snippets';
import { PIPES } from './pipes';


@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    TranslateModule
  ],
  providers: [
    SERVICES,
    
    MATERIAL_SETTINGS
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES,
    SNIPPETS,
    PIPES
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,

    COMPONENTS,
    DIRECTIVES,
    SNIPPETS,
    PIPES
  ]
})
export class SharedModule { }
