<div class="account-verification-container">
  <h2 mat-dialog-title>{{ 'auth.account confirmation.title' | translate }}</h2>
  <form [formGroup]="confirmationAccountForm">
    <mat-dialog-content>
      <div fxLayout="column" fxLayoutGap="1.0rem">
        <div fxLayout="column" fxLayoutGap="0.5rem">
          <mat-form-field>
            <mat-label>{{ 'auth.login.username' | translate }}</mat-label>
            <input matInput placeholder="{{ 'auth.login.username' | translate }}" formControlName="username">
            <mat-hint>{{ 'app.forms.is required' | translate: {item: ('auth.login.username' | translate)} }}</mat-hint>
            <mat-error *ngIf="confirmationAccountForm.controls.username.errors?.required && confirmationAccountForm.controls.username.touched">
              <span>{{ 'app.forms.is required' | translate: {item: ('auth.login.username' | translate)} }}</span>
            </mat-error>
          </mat-form-field>
          <div fxLayout="column" fxLayoutAlign="center center">
            <button fxFill mat-flat-button color="accent" (click)="requestVerificationCode()" [disabled]="confirmationAccountForm.controls.username.errors?.required" class="button-send-code">
              <span>{{ 'auth.account confirmation.resend confirmation' | translate }}</span>
            </button>
          </div>
        </div>
        <mat-form-field>
          <mat-label>{{ 'auth.account confirmation.confirmation code' | translate }}</mat-label>
          <input matInput placeholder="{{ 'auth.account confirmation.confirmation code' | translate }}" formControlName="confirmation_code">
          <mat-hint>{{ 'app.forms.is required' | translate: {item: ('auth.account confirmation.confirmation code' | translate)} }}</mat-hint>
          <mat-error *ngIf="confirmationAccountForm.controls.confirmation_code.errors?.required && confirmationAccountForm.controls.confirmation_code.touched">
            <span>{{ 'app.forms.is required' | translate: {item: ('auth.account confirmation.confirmation code' | translate)} }}</span>
          </mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button fxFill mat-raised-button color="primary" (click)="requestVerificationAccount()" [disabled]="confirmationAccountForm.invalid">
        <span>{{ 'app.actions.accept' | translate }}</span>
      </button>
    </mat-dialog-actions>
    <br>
  </form>
</div>
