import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService, untilDestroyed } from '@app/@core';

import { AWSAuthService, LoginContext } from '@app/@core/services/aws/auth.service';
import { DialogService } from '@app/@shared/services/dialog.service';
import { NotificationService } from '@app/@shared/services/notification.service';

import { PasswordRefactorComponent } from '../password-refactor/password-refactor.component';
import { PasswordRecoverComponent } from '../password-recover/password-recover.component';
import { AuthConfirmationAccountComponent } from '../confirmation-account/auth-confirmation-account.component';

import { environment } from '@env/environment';
import { MediaObserver } from '@angular/flex-layout';


@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss'],
})
export class AuthLoginComponent implements OnInit {

  landingUrl: string = environment.landingUrl;

  loginForm: FormGroup;
  loginFormEnum = LoginFormEnum;
  progressLogin: Boolean = false;
  hidePassword: Boolean = true;
  federaSignInError : boolean | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private awsAuthService: AWSAuthService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private media: MediaObserver) {

    this.federaSignInError = JSON.parse(localStorage.getItem('aws-amplify-federaSignInError'));
    localStorage.removeItem('aws-amplify-federaSignInError');
    this.createLoginForm();
  }
  //TODO Move to backend
  test = ['https://uploads-ssl.webflow.com/61b30e6ad88d4350b94fa0c6/61de03216ef8ec82dfd1349e_iStock-1197955157.jpg',
          'https://uploads-ssl.webflow.com/61b30e6ad88d4350b94fa0c6/61de039b9bf28f0f3285f927_iStock-1289739813.jpeg',
          'https://uploads-ssl.webflow.com/61b30e6ad88d4350b94fa0c6/620a51d934ab23d4b9c9f0cd_Pulses%20iStock-1284379612%20.jpg',
          'https://uploads-ssl.webflow.com/61b30e6ad88d4350b94fa0c6/61dae0c2ee276f8ef988c2c3_iStock-1170307116.jpeg',
          'https://uploads-ssl.webflow.com/61b30e6ad88d4350b94fa0c6/61de0363218d5e33cf5ec36c_iStock-1342398509.jpeg',
          'https://uploads-ssl.webflow.com/61b30e6ad88d4350b94fa0c6/61de01c666c3a35f3878cd0d_iStock-1284379612.jpeg',
          'https://uploads-ssl.webflow.com/61b30e6ad88d4350b94fa0c6/61de02f39da6ae6c07997b34_iStock-1290538282.jpeg',
        ];
  randomNumber : number;
  ngOnInit() {
    this.randomNumber = this.getRandomNumber(this.test.length);
    // [FORGOT CREDENTIALS] Alternative manual process
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment == 'forgot') {
        this.dialogService.open(PasswordRecoverComponent, { minWidth: "320px" }).subscribe(
          () => {
            untilDestroyed(this);
            this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
          }
        );
      }
    });
  }

  get isMobile(): boolean {
    return this.media.isActive('xs') || this.media.isActive('sm');
  }

  getRandomNumber(max : number) : number {
    return Math.floor(Math.random()*max);
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      [this.loginFormEnum.username]: [null, Validators.required],
      [this.loginFormEnum.password]: [null, Validators.required],
      [this.loginFormEnum.remember]: [true]
    });
  }

  login() {
    this.loginForm.value.username=this.loginForm.value.username.toLowerCase();
    const credentials: LoginContext = this.loginForm.value as LoginContext;

    this.progressLogin = true;
    this.loaderService.activateNavigation();

    this.awsAuthService.login(credentials)
      .then((user: any) => {
        // [REFACTOR CREDENTIALS] Alternative manual process
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.dialogService.open(PasswordRefactorComponent, { data: { user: user }, minWidth: '320px' } ).subscribe(
            () => {
              this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
              untilDestroyed(this);
            }
          );
        }

        // Login sucess > Redirect routing
        this.router.navigate([this.route.snapshot.queryParams.redirect || '/employees'], { replaceUrl: true });

        untilDestroyed(this);
      })
      .catch((error: any) => {
        if (error.code === 'UserNotConfirmedException') {
          this.dialogService.open(AuthConfirmationAccountComponent, { data: { username: credentials.username },  minWidth: "320px" }).subscribe(
            (result) => {
              if (result)
                this.login();
            }
          );
        }
        else if (error.code === 'NotAuthorizedException' && error.message.includes('User is disabled')) {
          this.notificationService.open("auth.login.authentication disabled");
        }
        else if (error.code === 'NotAuthorizedException' && error.message.includes('Incorrect username or password')) {
          this.notificationService.open("auth.login.authentication incorrect");
        }
        else {
          this.notificationService.open("auth.login.authentication failed");
        }
      })
      .finally(() => {
        this.progressLogin = false;
        this.loaderService.deactivateNavigation();
      })
  }

  loginIdentityFederated(provider: string) {
    this.awsAuthService.federatedSignIn(provider)
      .then((result) => {})
      .catch((error: any) => {});
  }
}
export enum LoginFormEnum {
  username = 'username',
  password = 'password',
  remember = 'remember'
}
