import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Logger } from '../logger.service';

import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { Hub } from '@aws-amplify/core';


const log = new Logger('AWSAPIService');


Hub.listen('api', (data) => {
  log.info("__[HUB] API", data);
});


@Injectable({
  providedIn: 'root'
})
export class AWSApiService {

  constructor() { }


  async getOperation(query: string, value: object): Promise<GraphQLResult<object>> {
    return API.graphql(graphqlOperation(query, value)) as GraphQLResult<object>;
  }

  async createOperation(mutation: string, value: object): Promise<GraphQLResult<object>> {
    return API.graphql(graphqlOperation(mutation, value)) as GraphQLResult<object>;
  }

  async updateOperation(mutation: string, value: object): Promise<GraphQLResult<object>> {
    return API.graphql(graphqlOperation(mutation, value)) as GraphQLResult<object>;
  }

  async deleteOperation(mutation: string, value: object): Promise<GraphQLResult<object>> {
    return API.graphql(graphqlOperation(mutation, value)) as GraphQLResult<object>;
  }

  suscribeOperation(subscription: string, value: object): Observable<object> {
    return API.graphql(graphqlOperation(subscription, value)) as unknown as Observable<object>;
  }
}
