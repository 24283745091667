// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
    production: false,
    serviceWorker: true,
    serverUrl: 'https://ifsgfa213k.execute-api.eu-west-1.amazonaws.com/teknei/api',
    landingUrl: 'https://app.teknei.qrbcard.net',
    version: env.npm_package_version + '-teknei',
    aws: {
      auth: {
        identityPoolId: 'eu-west-1:1f40ee0d-4ce2-4f26-b2ea-a3ef239978bb',
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_axaUVuJxJ',
        userPoolWebClientId: '1bjmhiedcppm1s268ahmsbqlrr',
        mandatorySignIn: true,
        oauth: {
            domain: "qrbcard-application-teknei.auth.eu-west-1.amazoncognito.com",
            scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: "https://app.teknei.qrbcard.net/",
            redirectSignOut: "https://app.teknei.qrbcard.net",
            responseType: 'token',
            options: {
                AdvancedSecurityDataCollectionFlag: false
            }
        },
      },
      storage: {
        s3: {
          region: 'eu-west-1',
          bucket: 'qrbcard.storage.teknei',
        }
      }
    },
    gcp: {
      gtm: {
        gtm_id: 'GTM-5H427KD',
        // gtm_auth: '',
        // gtm_preview: ''
      },
      recaptcha_key: '6Le0sDQiAAAAAP1ajtwoElXun--Lp0LCxxQRZso9'
    },
    cache: {
      Key: 'http_cache',
      Persistence: 'local', /* local / session / memory */
      Duration: 60*24 /* minutes */
    }
  };
