import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nService } from '@app/@core/services/i18n.service';

export interface PageSection {
  index?: number;
  title: string;
  fragment: string;
}

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  @ViewChild('drawer') public drawer: MatSidenav;

  expanded: boolean;
  currentPageSection: PageSection;
  pageSections: PageSection[] = [
    {
      title: 'About DCX',
      fragment: 'about-digital-commodity-exchange',
    },
    {
      title: 'About these T&C',
      fragment: 'about-terms-and-conditions',
    },
    {
      title: 'Definitions',
      fragment: 'definitions',
    },
    {
      title: 'Your use of DCX platform',
      fragment: 'your-use-of-DCX-platform',
    },
    {
      title: 'Amendments to these T&C',
      fragment: 'amendments',
    },
    {
      title: 'Registration',
      fragment: 'registration',
    },
    {
      title: 'Unique ID and Security',
      fragment: 'unique-id-and-security',
    },
    {
      title: 'Contracts Cancellation',
      fragment: 'contracts-cancellation',
    },
    {
      title: 'Intellectual Property',
      fragment: 'intellectual-property',
    },
    {
      title: 'Code of Conduct',
      fragment: 'code-of-conduct',
    },
    {
      title: 'Availability',
      fragment: 'availability',
    },
    {
      title: 'Exchange Fee',
      fragment: 'exchange-fee',
    },
    {
      title: 'Your Liability',
      fragment: 'your-liability',
    },
    {
      title: 'DCX Liability',
      fragment: 'dcx-liability',
    },
    {
      title: 'Disclaimers',
      fragment: 'disclaimers',
    },
    {
      title: 'Applicable Law',
      fragment: 'applicable-law',
    },
    {
      title: 'Disputes',
      fragment: 'disputes',
    },
    {
      title: 'Miscellaneous',
      fragment: 'miscellaneous',
    },
    {
      title: 'Termination',
      fragment: 'termination',
    },
    {
      title: 'Language',
      fragment: 'language',
    },
    {
      title: 'Warranties',
      fragment: 'warranties',
    }
  ]

  constructor(private route: ActivatedRoute, private i18nService: I18nService, private router: Router) { }

  ngOnInit(): void {
    // Initialize default section
    this.currentPageSection = this.pageSections[0];

    this.route.fragment.subscribe((fragment: string) => {
      if (fragment) {
        this.currentPageSection = this.pageSections.find(x => x.fragment === fragment);
        this.currentPageSection.index = this.pageSections.indexOf(this.currentPageSection);
        this.expanded = false;
      }
      else {
        this.router.navigate(['/legal'], {fragment: this.currentPageSection.fragment});
      }

      window.scroll(0,0);
    });

  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  onPreviousTab() {
    this.router.navigate(['/legal'], {
      fragment: this.pageSections[this.currentPageSection.index - 1]?.fragment || this.pageSections[this.pageSections.length - 1].fragment
    });
  }

  onNextTab() {
    this.router.navigate(['/legal'], {
      fragment: this.pageSections[this.currentPageSection.index + 1]?.fragment || this.pageSections[0].fragment
    });
  }

  closeDrawer() {
    this.drawer.toggle();
  }

  toggleDrawer(){
    this.drawer.toggle();
    this.expanded = !this.expanded;
  }

}
