import { Pipe, PipeTransform } from '@angular/core';
import { MASS_COLLECTION, MassUnit } from '@env/resources-settings';


@Pipe({ name: 'massUnitView', pure: false })
export class MassUnitViewPipe implements PipeTransform {

  massUnits: MassUnit[] = MASS_COLLECTION;

  transform(value: number, unitOrigin: string, unitTransform: string) {
    const massUnitOrigin = this.massUnits.find(c => c.code == unitOrigin);
    const massUnitTransform = this.massUnits.find(c => c.code == unitTransform);

    if (massUnitOrigin && massUnitTransform)
      return value * (massUnitOrigin.equivalence/massUnitTransform.equivalence);

    return value;
  }

}
