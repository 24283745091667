import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { AWSAuthService } from '@app/@core/services/aws/auth.service';


@Component({
  selector: 'app-auth-confirmation',
  templateUrl: './auth-confirmation.component.html',
  styleUrls: ['./auth-confirmation.component.scss'],
})
export class AuthConfirmationComponent implements OnInit {

  confirmationAccountForm: FormGroup;
  confirmationErrorMsg: any;
  confirmationOkMsg: any;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private awsAuthService: AWSAuthService,
  ) { }


  ngOnInit(): void {
    // CALLBACK Confirmation > From email confirmation
    const username = this.route.snapshot.queryParamMap.get('username');
    const confirmation_code = this.route.snapshot.queryParamMap.get('confirmation_code');

    if (username && confirmation_code)
      this.createForm(username, confirmation_code);
    else
      this.confirmationErrorMsg = "auth.account confirmation.confirmation parameters are not present";
  }

  private createForm(username: string, confirmation_code: string) {
    this.confirmationAccountForm = this.formBuilder.group({
      username: [username, [Validators.required]],
      confirmation_code: [confirmation_code, [Validators.required]]
    });

    if (this.confirmationAccountForm.valid)
      this.requestVerificationAccount();
  }

  requestVerificationAccount() {
    this.awsAuthService.confirmSignUp(
      this.confirmationAccountForm.value.username,
      this.confirmationAccountForm.value.confirmation_code
    ).then(
      () => {
        this.confirmationOkMsg = "auth.account confirmation.you can now proceed to log in with your account.";
      })
      .catch((error) => {
        this.confirmationErrorMsg = error.message;
      });
  }

}
