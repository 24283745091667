import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ComponentType } from '@angular/cdk/portal';

import { ConfirmComponent } from '../components/dialogs/confirm/confirm.component';
import { NotificationComponent } from '../components/dialogs/notification/notification.component';
import { MessageComponent } from '../components/dialogs/message/message.component';

import { IDialogData } from '../components/dialogs/dialogs.interfaces';


@Injectable()
export class DialogService {

  constructor(private dialog: MatDialog) { }

  openConfirmation(data: IDialogData, config?: MatDialogConfig): Observable<boolean> {
    const configuration = config || new MatDialogConfig()
    configuration.data = data;
    configuration.data.closeButton = configuration.disableClose;

    const dialog = this.dialog.open(ConfirmComponent, configuration);
    return dialog.afterClosed();
  }

  openNotification(data: IDialogData, config?: MatDialogConfig): MatDialogRef<NotificationComponent> {
    const configuration = config || new MatDialogConfig()
    configuration.data = data;
    configuration.data.closeButton = configuration.disableClose;

    return this.dialog.open(NotificationComponent, configuration);
  }

  openMessage(data: IDialogData, config?: MatDialogConfig) {
    const configuration = config || new MatDialogConfig()
    configuration.data = data;
    configuration.data.closeButton = configuration.disableClose;

    this.dialog.open(MessageComponent, configuration);
  }

  open<T>(component: ComponentType<T>, config?: MatDialogConfig): Observable<any> {
    const dialog = this.dialog.open(component, config);
    return dialog.afterClosed();
  }
}
