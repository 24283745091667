import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@core';


@UntilDestroy()
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
  constructor(public router: Router) { }
}
