
<div fxFlex.gt-sm="1000px" fxLayout="column" class="auth-terms-and-conditions-container">

  <!-- MOBILE BREADCRUMBS -->
  <div fxHide.gt-sm fxFlex="100%" fxLayout="row" fxFlex.lt-md="50px" fxLayoutAlign="start center" fxLayoutGap="0.5rem" class="tc-breadcrumbs-container">
    <a routerLink="/" fxLayoutAlign="start center">
      <mat-icon mat-list-icon class="home-icon">home</mat-icon>
    </a>
    <mat-icon class="custom-mat-icon">keyboard_arrow_right</mat-icon>
    <span class="dcx-text dcx-blue georama tc-breadcrumbs-title" routerLink="/legal"><strong>T&C</strong></span>
    <mat-icon class="custom-mat-icon">keyboard_arrow_right</mat-icon>
    <span class="dcx-text dcx-lighter-blue page-section-title-ellipsis" (click)="toggleDrawer()"><strong>{{ currentPageSection.title }}</strong></span>
    <mat-icon class="expand-more-arrow" [ngClass]="{ 'rotated': expanded }" (click)="toggleDrawer()">expand_more</mat-icon>
  </div>

  <mat-drawer-container [hasBackdrop]="true">

    <!-- MOBILE table of contents -->
    <mat-drawer #drawer mode="over" position="end" class="tc-drawer-container">
      <ul class="tc-navigation-list">
        <li *ngFor="let item of pageSections">
          <a routerLink="/legal" class="dcx-text dcx-lighter-blue" (click)="closeDrawer()"
            [class.active]="currentPageSection.fragment==item.fragment"
            [fragment]="item.fragment">{{ item.title }}
          </a>
        </li>
      </ul>
    </mat-drawer>

    <!-- TERMS AND CONDITIONS content -->
    <mat-drawer-content class="tc-content-main-container">

      <div fxLayout="row wrap" fxFlex.gt-sm="70%" class="tc-content-container" fxLayoutAlign="start start">

        <h1 fxFlex="100%" class="dcx-text dcx-h1 dcx-blue georama">
          <a routerLink="/legal"><strong>Terms & Conditions</strong></a>
        </h1>

        <h4 fxFlex="100%" class="dcx-text dcx-h4 lightgrey"><strong>Last updated November 5th, 2021</strong></h4>

        <mat-error *ngIf="currentLanguage !== 'en-US'">
          <strong><span>Cette section est disponible en anglais seulement. Désolé pour le dérangement.</span></strong>
        </mat-error>

        <!-- TERMS AND CONDITIONS TEMPLATES -->
        <mat-tab-group fxFlex="100%" [(selectedIndex)]="this.currentPageSection.index" mat-align-tabs="start" class="tc-mat-tab-group">

          <mat-tab *ngFor="let item of pageSections; let i=index">
            <ng-template mat-tab-label>
              <h5 [id]="item.fragment">
                <strong>{{ i }}</strong>
              </h5>
            </ng-template>

            <h3 fxfill class="dcx-text dcx-h3">
              <strong>{{ item.title }}</strong>
            </h3>

            <!-- TERMS AND CONDITIONS TEMPLATES -->
            <div class="tc-mat-tab-content">

              <!-- 0 About Grupo Vermon / qrbcard -->
              <ng-template [ngIf]="item.fragment === 'about-grupo-vermon'">

                <p>lorem ipsum
                </p>

              </ng-template>

              <!-- 1 About these Terms and Conditions of Use ("Terms") -->
              <ng-template [ngIf]="item.fragment === 'about-terms-and-conditions'">
                <p>These Terms govern Authorized Users and/or any Company and/or any Company Person (hereafter, "you" or "your") use of the QRBCARD Platform and their relationship with QRBCARD (hereafter "GRUPO VERMON",
                  "QRBCARD", "we" or "us").</p>

                <p>By registering for a QRBCARD ID or to become an Authorized User of the QRBCARD Platform you are entering into a legally binding contract subject to these Terms between you and
                  GRUPO VERMON (trading as QRBCARD). By using the QRBCARD Platform or registering a QRBCARD ID or becoming an Authorized User and/or Company Person you agree to accept
                  these Terms, including but not limited to the Warranties and any disclaimers and Policies which appear on the QRBCARD Platform. If you do not agree to these Terms and the Warranties, you must not
                  use the QRBCARD Platform. If you have any questions on the Terms, please contact <a href="mailto:soporte@grupovermon.com" class="dcx-text dcx-lighter-blue"><strong>soporte@grupovermon.com</strong></a>.</p>
              </ng-template>

              <!-- 2 Definitions -->
              <ng-template [ngIf]="item.fragment === 'definitions'">
                <p>"<strong>Agreement</strong>" means these Terms.</p>

                <p>
                  "<strong>Audit Log</strong>" means the audit trail of events produced pursuant to every transaction concluded on the QRBCARD Platform.
                </p>

                <p>
                  "<strong>Authorized User</strong>" means each person identified to have access to the QRBCARD Platform by a Company
                  who is the holder of a User ID. For the avoidance of doubt, Authorized Users include Buyers, Sellers and Service Providers, and their respective employees, delegates, servants and agents.
                </p>

                <p>
                  "<strong>Bid</strong>" means the price and conditions at which a Buyer offers to buy commodities made via a public or private Trade Request posted on the QRBCARD Platform. This also includes any
                  "Counter Bid".
                </p>

                <p>
                  "<strong>Buyer</strong>" means any individual or business entity who is an Authorised User who buys or intends to buy commodities using the QRBCARD Platform.
                </p>

                <p>
                  "<strong>Company</strong>" also referred to as "Companies" means the entity you are registering through the GRUPO VERMON Registration Process for access to the QRBCARD Platform;
                </p>

                <p>
                  "<strong>Company Person</strong>" means each of the Company's subsidiaries, affiliates, directors, officers, agents, employees or other persons acting on the Company's behalf;
                </p>

                <p>
                  "<strong>Contract</strong>" means the contract of sale which is concluded on the terms and specifications listed on the Digital
                  Commodity Exchange Platform and becomes binding on both parties (without any need for further signature or
                  execution) once a Buyer's Bid or Seller's Offer is accepted by the relevant counterparty clicking the "Accept" button on the relevant screen of the QRBCARD Platform.
                </p>

                <p>
                  "<strong>Exchange Fee</strong>" means the payment(s) made by QRBCARD Authorized Users or Companies with a QRBCARD ID to GRUPO VERMON for using the QRBCARD Platform to
                  facilitate the
                  conclusion of Contracts or the provision of services to Buyers and Sellers.
                </p>

                <p>"<strong>Goods</strong>" means physical commodities.</p>

                <p>
                  "<strong>Intellectual Property Rights</strong>" means any and all copyright, database rights, trade marks, know-how and other intellectual property rights, with registered or unregistered, in any jurisdiction in or
                  relating to
                  QRBCARD, the QRBCARD Platform, its site, system or service.
                </p>

                <p>
                  "<strong>Market</strong>" means the set facilities contained on the QRBCARD Platform through which Buyers and Sellers can buy and sell commodities.
                </p>

                <p>
                  "<strong>Offer</strong>" means the price and conditions at which a Seller wishes to sell commodities made via a public or private Trade Request posted on the QRBCARD Platform. This also includes
                  any "Counter Offer".
                </p>

                <p>
                  "<strong>Policy</strong>" or "<strong>Policies</strong>" means any separate set of terms or Policies which also apply to the use of our QRBCARD Platform. These are our Cookies Policy, Data
                  Protection Policy and any other set of
                  terms or disclaimers which may appear on the site from time to time.
                </p>

                <p>
                  "<strong>QRBCARD User ID</strong>" or "<strong>User ID</strong>" means the account set up when a Company or Authorised User is registered for access to the QRBCARD Platform via
                  the GRUPO VERMON Registration Process.
                </p>

                <p>
                  "<strong>QRBCARD Password</strong>" means the unique sequence of characters used to authenticate a QRBCARD User ID on the QRBCARD Platform.
                </p>

                <p>
                  "<strong>QRBCARD Company Administrator</strong>" means the person designated by any corporate entity using the QRBCARD Platform who can administer the User IDs of employees or
                  associates of that
                  entity including but not limited to addition, activation, deletion and deactivation of User IDs, passwords and restriction or variation of permissions.
                </p>

                <p>"<strong>QRBCARD Registration Process</strong>" - the process to gain access to the QRBCARD Exchange Platform, which includes the Warranties.</p>

                <p>
                  "<strong>Seller</strong>" means any individual or business entity who is a QRBCARD Authorized User who sells or intends to sell commodities using the QRBCARD Platform.
                </p>

                <p>
                  "<strong>Service</strong>" means the service provided to QRBCARD Authorized Users or holders of a QRBCARD IDs by QRBCARD by way of the QRBCARD Platform or any
                  combination or component thereof.
                </p>

                <p>
                  "<strong>Service Providers</strong>" are QRBCARD Users who are not Buyers or Sellers, but who provide services to the commodities trade, including, but not limited to, banks, shipping companies,
                  freight forwarders,
                  surveyors, and insurers. For the avoidance of doubt, where there is no agreement as to the applicability of any other terms, these Terms and Conditions shall apply, insofar as they may be relevant, to a Service
                  Provider's use of or
                  participation on the QRBCARD Platform.
                </p>

                <p>
                  "<strong>Smart Contract</strong>" means the document setting out the terms of the binding contract of sale which is automatically generated once a Buyer's Bid or Seller's Offer is accepted by the relevant
                  counterparty clicking the
                  "Accept" button on the relevant screen of the QRBCARD Platform.
                </p>

                <p>
                  "<strong>Trading Information</strong>" means any information which may be provided by either a Seller or a Buyer or Service Provider in relation to their status, capacity to trade, commodities being sold and bought
                  and any other information
                  or documents exchanged between Seller and Buyer and/or any Service Provider, whether such information or document is displayed on or uploaded to the QRBCARD Platform or otherwise passed between
                  Seller and Buyer.
                </p>

                <p>
                  "<strong>Trade Request</strong>" means the information contained in a Buyer's Bid/Buyers' buying interest or Seller's Offer as the case may be.
                </p>

                <p>
                  "<strong>Warranties</strong>" means the "Company / Authorised User Warranties" set forth immediately following these Terms, which are incorporated herein by reference as if fully set forth herein and are subject to
                  these Terms in all respects.
                </p>

              </ng-template>

              <!-- 3 Your use of QRBCARD platform -->
              <ng-template [ngIf]="item.fragment === 'your-use-of-QRBCARD-platform'">
                <p>The QRBCARD Platform is provided to you for use subject to these Terms, Warranties and
                  our Policies. Accordingly, by using the QRBCARD Platform you agree to be bound by these Terms, Warranties, our Policies and any Disclaimers.</p>
              </ng-template>

              <!-- 4 Amendments to these Terms -->
              <ng-template [ngIf]="item.fragment === 'amendments'">
                <p>We may update these Terms and the Warranties from time to time for legal or regulatory reasons or to facilitate the proper operation of the QRBCARD Platform. Any changes will be notified to you via
                  the e-mail address provided by you on registration or via a suitable announcement on the QRBCARD Platform. If you continue to use the QRBCARD Platform after the date on which the
                  change comes into effect, your use of the QRBCARD Platform will be deemed to constitute your agreement to be bound by the new Terms and Warranties.</p>
              </ng-template>

              <!-- 5 Registration -->
              <ng-template [ngIf]="item.fragment === 'registration'">

                <p>To register on the QRBCARD Platform you must provide QRBCARD with all information and details as prompted by the registration screen during the QRBCARD Registration Process together with the Warranties and such further
                  information as the QRBCARD may, in its absolute discretion request. You must ensure that the details provided by you on registration or at any time are correct and complete.</p>

                <p>You shall be solely liable for any losses, damage or claims arising out of your failure to provide and maintain accurate details or information.</p>

                <p>All personal data we collect from you, or that you provide to us, will be processed by us. Personal data is and shall at all times be dealt with as set out in our Data Protection Policy. We reserve the right to monitor all
                  data, activities, communications including but not limited to the Audit Log for any purpose we deem fit in line
                  with our Data Protection Policy.</p>

                <p>By registering as an Authorised User and/or Company Person and agreeing to the Warranties you warrant you have the consent and authority to register on behalf of yourself, or if employed as an employee or agent for
                  another third party or organisation, you have the authority to trade on behalf of such third party or organisation and, to the extent applicable, to provide the required details.</p>

                <p>If you provide us with any information which is not true, complete or current or if we suspect that such information is not true or accurate, we have the right to terminate or suspend your account at our sole discretion.
                </p>

                <p>You must inform us immediately of any changes to the information that you provided when registering by updating your details so that we can communicate with you effectively.</p>

                <p>You are solely responsible for maintaining and updating your registration information, Warranties and User ID.</p>

                <p>If you wish to cancel your registration and terminate this Agreement then you may do so at any time (provided that you are not engaged in any transaction or dispute relating to a transaction, and/or providing that no
                  exchange fees owed to QRBCARD are outstanding) by notifying your QRBCARD Company Administrator. QRBCARD may take up to ten (10) business days to complete the cancellation process.</p>

              </ng-template>

              <!-- 6 Unique ID and Security -->
              <ng-template [ngIf]="item.fragment === 'unique-id-and-security'">

                <p>On completion of the QRBCARD Registration Process you will be allocated a User ID and you will create a password.</p>

                <p>In order to prevent fraud, it is a condition of this Agreement that you keep your unique User ID and password confidential and you agree not to disclose it or share it with anyone. If you know or suspect that someone
                  else knows your User ID or password you undertake to notify your QRBCARD Company Administrator immediately. We will not be liable for any loss occasioned by any unauthorised user accessing your account.</p>

                <p>You shall ensure that all your employees comply with this Agreement and the Warranties and if you wish to withdraw any permissions granted to one or more of your employees or prevent an employee from accessing a
                  particular User ID you must advise your QRBCARD Company Administrator who will take the necessary steps to deactivate that particular employee(s) via the Management Tool available to him. You will remain responsible for all
                  actions carried out on the QRBCARD Platform by any such employee until such time as their permissions have been withdrawn or their user account has been deactivated by your QRBCARD Company
                  Administrator and thereafter in respect of any activity on or in connection with the QRBCARD Platform.
                </p>

                <p>You are solely responsible for all actions taken on or in connection with the QRBCARD Platform using the User ID allocated to you or your employees, whether or not that action was taken by you or a person
                  authorised by you, and whether or not that action has been carried out fraudulently, negligently or otherwise.</p>

                <p>If we have reason to believe that there has been or is likely to be a breach of security or misuse of the QRBCARD Platform, we may require you to change your User ID and/or password or we or your
                  QRBCARD Company Administrator may suspend or de-activate your User ID or the User ID of your employees, servant or agents</p>

              </ng-template>


              <!-- 7 QRBCARD's right to cancel Contracts in the event of fraud, illegality or misuse -->
              <ng-template [ngIf]="item.fragment === 'contracts-cancellation'">
                <p>QRBCARD may, in our absolute discretion, cancel or amend Contracts concluded via the QRBCARD Platform if we believe that this is necessary to maintain fair and orderly trading and/or the integrity
                  of the Platform or where we are satisfied that the Contract concerned was illegal, concluded through fraud or through the misuse of a User ID. This will only be done in rarely and in exceptional circumstances,
                  and the fact that a trade was made by mistake or in error will not usually be, by itself, a basis for cancelling or amending a Contract.</p>

                <p>Any request to us for a cancellation by an Authorised User and/or Company Person must be made as quickly as possible after the relevant Contract has been concluded to the following email address admin@QRBCARD.group.
                  Any delay in making such a request will significantly reduce the likelihood that QRBCARD will cancel or amend the Contract.</p>

                <p>For the avoidance of doubt no Authorised User and/or Company Person will have the right to cancel or amend any Contract concluded on the QRBCARD Platform unilaterally. However, for the avoidance
                  of
                  doubt, nothing in this clause is to be taken as affecting any Authorised User's and/or Company Person's and/or Company's contractual or legal rights of rejection or termination, or any other rights that user may
                  legitimately have to treat itself as discharged from a Contract pursuant to its governing law.</p>

              </ng-template>

              <!-- 8 Intellectual Property -->
              <ng-template [ngIf]="item.fragment === 'intellectual-property'">
                <p>The software and technology associated with and the content of the QRBCARD Platform is protected by copyright, trademarks, database rights and all other Intellectual Property Rights. You
                  may retrieve, display the content of the QRBCARD Platform on a computer screen for your own legitimate business use, and/or print this, pursuant to your ordinary use of the Platform,
                  provided you keep intact and respect all and any copyright and proprietary notices. You may not otherwise reproduce, modify, copy or distribute or use for commercial purposes any of the materials or content
                  on the QRBCARD Platform without written permission from QRBCARD.</p>

                  <p>
                    You must not (and must not permit any third party to) attempt to copy, adapt, reverse engineer, decompile, disassemble, modify, adapt or make error corrections to the QRBCARD Platform or
                    to any software, coding or programming used on the Platform.
                  </p>

                  <p>
                    QRBCARD reserves to itself and is the owner of all proprietary information and data relating to Authorised Users, Companies or Company Persons ("Customer Data") and you are expressly forbidden from procuring
                    or attempting to procure, collect or make any use of Customer Data. The prohibitions contained in this clause 21 do not preclude you from being able to use data from the QRBCARD Platform
                    to contact other Authorised Users via the QRBCARD Platform for the purposes of making Bids, Offers, or Trade Requests or concluding Contracts on the QRBCARD Platform.
                  </p>
              </ng-template>

              <!-- 9 Code of Conduct governing Use of the QRBCARD Platform -->
              <ng-template [ngIf]="item.fragment === 'code-of-conduct'">

                <p>You hereby acknowledge that acceptance of an Offer or Bid on the QRBCARD Platform gives rise to a binding contractual obligation, on (i) the Buyer to buy the relevant parcel(s) of
                  commodities and (ii) the Seller to sell the relevant parcel(s) of commodities, on the terms of the Contract. Accordingly, to preserve the reputation and integrity of the QRBCARD Platform
                  you hereby agree to perform your obligations under any Contract concluded on it when these arise or when you are legitimately called upon by your counterparty to do so. However, for the avoidance of doubt,
                  nothing in this clause or these Terms is to be taken as affecting your contractual or legal rights of rejection or termination, or any other rights you may legitimately have not to perform, or to suspend or
                  withhold performance under the Contract or pursuant to its governing law.</p>

                  <p>
                    You shall take all proper care to avoid making misrepresentations to your counterparty in any Bid, Offer, Trade Request or negotiations, (whether conducted via the QRBCARD Platform's
                    Instant Chat functionality or otherwise). You represent, warrant and agree that you have obtained all the necessary copyright, trademark, trade secret, patent licences or permissions as may be required in
                    for any material or information posted on or authorised to appear on the QRBCARD Platform.
                  </p>

                  <p>
                    You agree to comply with all applicable regulatory, consumer, food safety, statutory, fiscal or other requirements associated with the trade including but not limited to those at the port of shipment
                    and/or port of discharge as well as bribery, sanctions, anti-corruption, anti-competition and any other financial crime laws, in any applicable jurisdiction, and you undertake to notify us immediately if you
                    become aware of or have concerns about any transaction or if you are in breach of your obligations under this clause in this regard.
                  </p>

                  <p>You may not use the QRBCARD Platform for any of the following purposes:</p>

                  <ol type="a">
                    <p><li>disseminating any unlawful, harassing, libellous, abusive, threatening, harmful, vulgar, obscene, or otherwise objectionable material or otherwise breaching any laws in any relevant or applicable jurisdiction;</li></p>
                    <p><li>facilitating conduct that constitutes a criminal offence, results in civil liability or otherwise breaches any applicable laws, regulations or code of practice, including but not limited to sanctions,
                      laws relating to bribery and corruption and laws relating to money laundering and criminal activity and anti-competition;</li></p>
                      <p><li>placing incorrect, misleading, false or otherwise fraudulent offers, bids or trades which may create or are likely to create a false or misleading impression as to the market for or price of
                        commodities and/or making any misrepresentations in relation to your identity, legal entitlement or capacity to trade e.g. by making a Trade Request with no intention to conclude a Contract</li></p>
                      <p><li>interfering with any other Authorised Users' use of the QRBCARD Platform; or</li></p>
                      <p><li>making, transmitting or storing electronic copies of materials protected by copyright without the permission of the QRBCARD.</li></p>
                  </ol>

                  <p>You will be responsible for any and all liabilities, whether direct, indirect or consequential arising out of your misuse of the QRBCARD Platform and/or breach of clauses 22-25 above,
                    the Warranties, including any losses or costs incurred by QRBCARD in relation to the same.</p>

                  <p>You may not use user information procured from the QRBCARD Platform to contact other Authorised Users other than via the QRBCARD Platform including but not limited
                    to the purposes of making Bids, Offers, or Trade Requests or concluding Contracts other than through the QRBCARD Platform. Service Providers may contact Authorised Users via alternative
                    means with the express consent of QRBCARD.</p>

                  <p>Authorised Users can leave comments, feedback, and ratings to let other users know about their experience. You have 90 days from the date of completion of your transaction to leave your rating and comments.
                    Before you leave a critical rating you should resolve disputes with us or other users amicably first. The QRBCARD Platform reserves the right to review feedback before it is posted,
                    remove feedback that isn't directly related to the trade or violates our Terms. If your comments include any promotional content, obscene or abusive language, personal information your feedback is potentially
                    subject to removal. If you breach this Code of Conduct the QRBCARD shall be entitled, in its sole discretion, to suspend you from the QRBCARD Platform and from further use of its system.</p>

              </ng-template>

              <!-- 10 Availability of the QRBCARD Platform -->
              <ng-template [ngIf]="item.fragment === 'availability'">

                <p>Although QRBCARD aim to offer you the best service possible, we give no undertaking or warranty that the facilities offered by the QRBCARD Platform will meet your requirements. We also cannot
                  guarantee that the service will be fault-free. Should you experience any difficulty in using the Platform you should report this to us at <a href="mailto:support@dcx.group"
                    class="dcx-text dcx-lighter-blue"><strong>support@dcx.group</strong></a> or the "GET SUPPORT" button and we will attempt to address
                  the problem as soon as we reasonably can.</p>

                <p>Your access to the QRBCARD Platform may be occasionally restricted to allow for repairs, maintenance or the introduction of new facilities or services. We will attempt to restore the service as
                  soon as we reasonably can.</p>

                <p>In order to prevent unfair or disorderly trading, QRBCARD at its sole discretion, has the right to suspend all trading on the QRBCARD Platform or impose such conditions on trading as it may deem
                  appropriate.</p>

              </ng-template>


              <!-- 11 Exchange Fee -->
              <ng-template [ngIf]="item.fragment === 'exchange-fee'">

                <p>The Exchange Fees and any other applicable charges will be those as determined by QRBCARD from time to time. You agree to promptly pay all applicable Exchange Fees. Exchange Fees are not subject to set off
                  or reduction and in the event that these are not paid within the appropriate timeframe, interest may be charged at 5% on any sums outstanding.</p>

              </ng-template>


              <!-- 12 Your Liability -->
              <ng-template [ngIf]="item.fragment === 'your-liability'">

                <p>To the extent not otherwise set out in these Terms and Warranties, you shall be liable for all your activity in relation to your User ID and use of the QRBCARD Platform and you shall
                  indemnify us to the fullest extent permitted by law for any and all losses, damage or claims whether direct, indirect or consequential arising out of your failure to comply with these Terms and Warranties or
                  otherwise.
                </p>
                <p>You are fully and solely liable for all your activity as Seller or Buyer or Service Provider as the case may be and any and all liabilities arising between those Sellers or Buyers or Service Providers
                  with whom you negotiate, trade or otherwise interact with on the QRBCARD Platform shall be solely for your account and shall never be or become the responsibility of QRBCARD.</p>
                <p>The performance, payment and execution of a Contract concluded via the QRBCARD Platform is entirely your own responsibility and the responsibility of your counterparty. QRBCARD is not in
                  any way responsible for the performance, payment or execution of any Contract.</p>

                <p>Without limiting the generality of anything in paragraph 35, you warrant that whether as a Seller or Buyer or Service Provider you have the full legal authority and capacity to make and/or accept Bids, Offers and
                  Trade Requests, conclude Contracts, provide services and/or to make payments and you hereby undertake to fully indemnify us against any and all claims that may arise from other Authorised Users as a result of your
                  failure to comply with these Terms and Warranties or otherwise.
                </p>
                <p>You warrant that any activity (including but not limited to Bid, Offer, Trade Request, Contract, etc.) you may engage in or conclude is lawful and will not place you, your counterparty or QRBCARD in breach of any relevant
                  law, including but not limited to:</p>
                <ol type="a">
                  <p>
                    <li>any sanctions imposed by the United States of America, the United Nations, the European Union, Singapore, the United Kingdom, Switzerland, or any other national or supranational body having authority or
                      jurisdiction over any relevant activities or parties;</li>
                  </p>
                  <p>
                    <li>any laws or regulations relating to the prevention of bribery and corruption;</li>
                  </p>
                  <p>
                    <li>any laws or regulations relating to money laundering, financing terrorism, and the proceeds of criminal activity.</li>
                  </p>
                </ol>
                <p>If, at any stage, you become aware of circumstances which may place you, your counterparty or QRBCARD in breach of any relevant law, including but not limited to statutes and regulations of the type detailed above in
                  clause 37, you will immediately provide us with full details of the same. In the event QRBCARD considers that any such laws have or may have been infringed it may, in addition to any other remedy it may have, suspend all
                  affected QRBCARD accounts forthwith and/or cancel or amend any affected Contracts concluded via the QRBCARD Platform.</p>
                <p>You hereby undertake not to sue QRBCARD or any of its servants, agents, employees, transferees or assignees, in respect of any breach or alleged breach by any party of any Contract or other transaction entered into via
                  the QRBCARD Platform and you hereby agree to co-operate fully with all requests from us in connection with such an event or claim (including in the defence of such event or claim.)
                </p>

              </ng-template>

              <!-- 13 QRBCARD's liability -->
              <ng-template [ngIf]="item.fragment === 'dcx-liability'">

                <p>Save as may be expressly stated in these Terms and Warranties, QRBCARD shall have no liability in relation to any Authorised User who has entered into a transaction with another Authorised User, or who has received or
                  provided any other service or document or Trading Information, through the QRBCARD Platform. We do not make any representation nor do we give any warranties or undertakings whatsoever in relation
                  to
                  the content, use and/or operation of the QRBCARD Platform or any Trading Information. Any representation, condition or warranty which might be implied or incorporated into these Terms by statute,
                  common law or otherwise is excluded to the fullest extent permitted by law.</p>

                <p>We (including our directors, agents and employees) shall not be liable to you in contract, tort (including negligence) or otherwise for any business losses, such as loss of data, profits, revenue, business,
                  opportunity, goodwill, reputation or business interruption or any special, indirect or consequential damages for any losses arising, directly or indirectly from:
                </p>
                <ol type="a">

                  <p>
                    <li>your use of, or your inability to use, our Service;
                    </li>
                  </p>

                  <p>
                    <li>your use of or any reliance on any content displayed on or document uploaded to the QRBCARD Platform or any Trading Information;</li>
                  </p>

                  <p>
                    <li>any delays or disruptions in our Service;</li>
                  </p>

                  <p>
                    <li>viruses or other malicious software obtained by accessing, or linking to, our Service;</li>
                  </p>

                  <p>
                    <li>glitches, bugs, errors or inaccuracies of any kind in our Service;</li>
                  </p>

                  <p>
                    <li>damage to your hardware device from use of our Service;</li>
                  </p>

                  <p>
                    <li>the information, content, actions or omissions of third parties, even if these arise by way of your use of our Service;</li>
                  </p>

                  <p>
                    <li>a suspension or other action taken with respect to your account as a result of your breach of these Terms.</li>
                  </p>

                </ol>

                <p>QRBCARD acts solely as a provider of the QRBCARD Platform and it is not in any way a principal in relation to, or responsible for the performance or non-performance of, any Contract concluded on or
                  using the QRBCARD Platform. The commercial purpose of the QRBCARD Platform is to provide the global commodities trade with a portal which allows prospective Buyers and Sellers
                  to
                  negotiate directly the transaction which best meets their respective needs without the need for or expense of an intermediary. Accordingly, when a Seller and a Buyer conclude a Contract using the Digital Commodity
                  Exchange Platform, those two parties' contract with each other directly and the QRBCARD is not a vendor, purchaser, broker, agent or any other category of intermediary. Accordingly, no Seller or
                  Buyer will ever enter into any express or implied Contract with us by bidding for or offering commodities for sale. The QRBCARD Platform is not a regulated entity, nor is it a provider of
                  financial,
                  trading, investment, legal or advice of any other nature. All trading and investment decisions are made by Users at their own risk. Buyers and Sellers shall remain fully responsible for their activity and decisions
                  made in connection with or during use of the QRBCARD Platform.
                </p>

                <p>We cannot confirm, and we are not in any way responsible for ensuring, the accuracy or truthfulness of any Authorised User's and or Company and/or Company Person's purported identity, credit-worthiness, capacity
                  to
                  trade, nor the accuracy of any information which any user may provide to us or post on the QRBCARD Platform. It is the sole obligation of each Authorised User to bear, and satisfy itself as to,
                  any
                  counterparty risk posed by another Authorised User and/or to decide (i) whether to contract with that other Authorised User and if so, (ii) on what terms. QRBCARD accepts no liability whatsoever in relation to this.
                </p>

                <p>As an Authorised User of the QRBCARD Platform you are hereby acknowledging and warranting that you have fulfilled any and all regulatory, consumer, food safety, statutory, fiscal or other
                  requirements associated with the trade including but not limited to those and the port of shipment and/or port of discharge. The QRBCARD Platform is not in any way liable for ensuring that Users
                  fulfil their own aforementioned regulatory requirements and will not be held responsible for any failures of Users to meet such requirements.
                </p>

                <p>QRBCARD also accepts no liability for (i) the making or processing of any payments by a Buyer to a Seller, or (ii) the shipment of any physical commodities bought, and/or (iii) the execution or
                  other performance of any Contract concluded on the QRBCARD and you expressly acknowledge and disclaim any liability of and towards QRBCARD in this regard.
                </p>

                <p>If, notwithstanding the above, we are found to be liable to you, our liability to you or any third party (whether in contract, tort, negligence, strict liability in tort, by statute or otherwise) is limited to 100
                  United States Dollars (US$100) per transaction.
                </p>

                <p>Nothing in this Agreement shall limit or exclude our liability for fraudulent misrepresentation, for death or personal injury resulting from our negligence or the negligence of our agents or employees or for any
                  other
                  liability that cannot be limited or excluded by law.</p>

                <p>For the avoidance of doubt QRBCARD also does not exclude or restrict any duty or liability which we may owe under any regulatory system imposed by any relevant and applicable statute or governmental regulation.</p>

              </ng-template>


              <!-- 14 Disclaimers -->
              <ng-template [ngIf]="item.fragment === 'disclaimers'">

                <p>Whilst we shall use reasonable endeavours to ensure that the information on the QRBCARD Platform provided by QRBCARD is correct, we do not warrant the accuracy and completeness of any content on the
                  QRBCARD Platform provided by us, any QRBCARD User or any third party. We may make changes to the content on the QRBCARD Platform at any time without notice.
                </p>

                <p>Accordingly, all content on the QRBCARD Platform provided by QRBCARD is provided on a "without guarantee" and "as is" basis, without any conditions, warranties or other terms of any kind.
                  Accordingly,
                  to the maximum extent permitted by law, the Service we provide with the QRBCARD Platform is provided on the basis that we exclude all representations, warranties, conditions and other terms
                  (including, without limitation, the conditions implied by law of satisfactory quality, fitness for purpose and the use of reasonable care and skill) which, but for this legal notice, might have effect in relation
                  to
                  the QRBCARD Platform.</p>

                <p>Any agreement you enter into with a third-party Service Provider is subject to the terms and conditions you have entered with that provider and stands alone from the activities and Terms of this Platform. The
                  Digital
                  Commodity Exchange Platform has no liability for performance of or fulfilment of any contract by any Service Provider and you remain responsible for paying for any such services. Any dispute between you a Service
                  Provider must be directly resolved with them in accordance with the applicable terms and conditions and the QRBCARD Platform shall have no duty to intervene to bring about resolution.
                </p>

              </ng-template>

              <!-- 15 Applicable Law -->
              <ng-template [ngIf]="item.fragment === 'applicable-law'">

                <p>This Agreement will be subject to English Law. We will try to solve any disagreements quickly and efficiently. Any dispute or claim arising out of or in connection with this Agreement, including any question regarding
                  its existence, validity or termination, and any dispute or claim arising out of or in connection with its subject matter or formation (including non-contractual disputes or claims), will be referred to and finally
                  resolved by an arbitration administered by the Singapore International Arbitration Centre ("SIAC") in accordance with the Arbitration Rules of SIAC then in force, which rules will be deemed to be incorporated by
                  reference into this Agreement. The seat of the arbitration will be Singapore. The tribunal will consist of one arbitrator to be appointed by the Chairman of SIAC. The language of the arbitration will be English. The
                  award of the arbitrator will be final and binding upon the parties to this Agreement.</p>

              </ng-template>


              <!-- 16 Disputes between Buyers, Sellers or Service Providers -->
              <ng-template [ngIf]="item.fragment === 'disputes'">

                <p>All trades will be marked "closed" by the QRBCARD Platform 90 days after the Buyer has effected payment to the Seller.</p>

                <p>You shall hold harmless and indemnify QRBCARD in relation to all Disputes.</p>

                <p>For the avoidance of doubt, nothing in clause 54 and/or above shall affect the operation of any time bar or an Authorised User's rights and/or obligations under any dispute resolution clause, choice of law or
                  jurisdiction or forum clause or any arbitral provision contained in any Contract concluded using the QRBCARD Platform.</p>

                <p>However, where a Buyer or Seller fails to resolve or respond to a Dispute in a commercial and professional manner, QRBCARD shall be entitled, in its sole discretion, to suspend one or both parties from the Digital
                  Commodity Exchange Platform and from further use of its system. </p>

              </ng-template>


              <!-- 17 Miscellaneous -->
              <ng-template [ngIf]="item.fragment === 'miscellaneous'">

                <p>You may not transfer any of your rights under these Terms to any other person unless approved in writing by QRBCARD. We may transfer our rights under these Terms to another business where we reasonably believe your
                  rights will not be affected.</p>

                <p>There shall be no waiver of QRBCARD's rights. If you breach these Terms and QRBCARD chooses to ignore this, QRBCARD will still be entitled to use its rights and remedies at a later date or in any other situation where you
                  breach the Terms.</p>

                <p>QRBCARD shall not be responsible for any breach of these Terms or Warranties caused by circumstances beyond its reasonable control.</p>

                <p>To the extent not otherwise provided on the QRBCARD Platform, all notices ("Notices") to us under this Agreement shall be in writing and sent to 4 Battery Road, Bank of China Building No 25-01,
                  Singapore 049908. All Notices we send to you shall be sent via email to the email address you provided on registration or may be published on the QRBCARD Platform.</p>

                <p>The provisions of this Agreement are severable. If any part of these Terms is determined, by court or a relevant authority, to be legally invalid or unenforceable, such provision will be superseded by a valid,
                  enforceable provision that most closely matches the intent of the original provision and the remainder of the Terms will continue in full force and effect.</p>

                <p>This Agreement is only between you and us. No other person shall have any rights to enforce any of its Terms, whether under the Contracts (Rights of Third Parties Act) 1999 or otherwise.</p>

                <p>The provisions of the United Nations Convention on the International Sale of Goods shall not apply to this Agreement, nor, unless the parties expressly agree otherwise, shall this Convention apply to any Contract
                  concluded on or via the QRBCARD Platform</p>

                <p>These Terms, the Warranties and any other policies referred to, form the entire agreement between you and us concerning the use of our Service and supersede all previous agreements. No agency, partnership, joint
                  venture, employer- employee, franchise-franchisee is intended or created by this Agreement.</p>

              </ng-template>


              <!-- 18 Termination -->
              <ng-template [ngIf]="item.fragment === 'termination'">

                <p>QRBCARD shall have the right to immediately suspend or terminate your registration, use and access to the QRBCARD Platform without cause for any reason it sees fit.</p>

                <p>If you wish to terminate this Agreement then you may do so by cancelling your registration in accordance with clause 10 above.</p>

                <p>These Terms and our Policies will continue to govern the relationship between you and QRBCARD after Termination.</p>

              </ng-template>


              <!-- 19 Language -->
              <ng-template [ngIf]="item.fragment === 'language'">

                <p>We will communicate with you in English only.</p>

                <p>The QRBCARD Platform and these Terms are concluded in English only. Any translation of the QRBCARD Platform or these Terms by you, your search engine, a professional
                  translator or otherwise is provided solely for your convenience and is not intended to modify the contents of this Agreement. In the event of a conflict between the English version of the QRBCARD
                  Platform or these Terms and a version in a language other than English, the English version shall be the definitive version.</p>

              </ng-template>


              <!-- 20 Warranties -->
              <ng-template [ngIf]="item.fragment === 'warranties'">

                <p>These Company / Authorised User Warranties ("<strong>Warranties</strong>") are incorporated by reference into the QRBCARD Terms and Conditions of Use (the "<strong>Terms</strong>")
                  as if fully set forth therein and are subject to the Terms in all respects. All capitalized terms appearing in these Warranties have the meanings assigned to them in the Terms.
                </p>

                <p> By ticking the box below, and each time you access the QRBCARD Platform, your Company and you as Authorised User jointly and severally represent and warrant that each of the following
                  Warranties is true, accurate,
                  complete and not misleading in all respects as it applies to your Company and to you as Authorised User. If any part of these Warranties is not true, accurate, complete and not misleading, you have an affirmative
                  duty promptly to
                  notify QRBCARD before further accessing the QRBCARD Platform; any failure to so notify will breach the Terms and may result in additional consequences. Authorised Users are
                  deemed to make
                  these Warranties with respect to their Company and Company Persons to the best of their knowledge and belief, upon diligent and careful inquiry.
                </p>

                <ol>
                  <li> Neither Company / Authorised User nor any Company Person: (i) is an individual or entity that is currently the subject of any sanctions administered or enforced by (A) the U.S. Department of State or the U.S.
                    Department
                    of the Treasury (including the Office of Foreign Assets Control ("<strong>OFAC</strong>")), in the U.S., (B) Her Majesty's Treasury in the United Kingdom, (C) the United Nations Security Council, (D) the European
                    Union or
                    (E) any other relevant sanctions authority (collectively, "<strong>Sanctions</strong>"); (ii) is an individual or entity whose name appears on the list of Specially Designated Nationals and Blocked Persons
                    published by OFAC
                    (or any similar list maintained by, or any sanctions designation made by, the U.S. Department of State or any other U.S. government entity) (a "<strong>Listed Person</strong>"); (iii) is an agent, department or
                    instrumentality
                    of, or is otherwise beneficially owned by, controlled by or acting on behalf of, directly or indirectly, (x) any Listed Person or (y) any individual or entity, organization, foreign country or regime that is the
                    subject of any
                    Sanctions; or (iv) engages in any dealings or transactions with any such person described in subsections (i), (ii) or (iii) of this paragraph.
                  </li>

                  <li>Neither Company / Authorised User nor any Company Person: (i) has been found in violation of any Sanctions or charged with or convicted of a violation of any Sanctions; (ii) is under investigation by any
                    governmental authority for possible violation of any Sanctions; or (iii) has been assessed civil penalties under any Sanctions. Company has adopted policies and procedures designed to promote and achieve
                    compliance
                    on the part of Company and all Company Persons with all Sanctions.</li>
                  <li>No legal, governmental or regulatory investigations, actions, suits or proceedings are pending or threatened against Company / Authorised User or any Company Person, and Company / Authorised User has never been
                    arrested, charged with or indicted for a misdemeanor, felony or other crime or been convicted of any misdemeanor, felony or other crime.</li>

                  <li>Company and each of its subsidiaries have been duly incorporated or organized and are validly existing and, where applicable, in good standing under the laws of their respective jurisdictions of incorporation
                    or
                    organization, are duly qualified to do business and, where applicable, are in good standing in each jurisdiction in which their respective ownership or lease of property or the conduct of their respective
                    businesses
                    requires such qualification and have all power and authority necessary to own or hold their respective properties and to conduct their respective businesses.</li>

                  <li>Company and its subsidiaries possess all licenses, certificates, permits and other authorizations issued by, and have made all declarations and filings with, the appropriate governmental or regulatory
                    authorities
                    that are necessary for the ownership or lease of their respective properties and the conduct of their respective businesses; and neither Company / Authorised User nor any of its subsidiaries has received notice
                    of
                    any revocation or modification of any such license, certificate, permit or authorization or has any reason to believe that any such license, certificate, permit or authorization will not be renewed in the
                    ordinary
                    course.</li>
                  <li>Neither Company / Authorised User nor any Company Person: (a) has violated (or has taken any action, directly or indirectly, that would result in a violation of): (x) any anti-bribery laws and / or
                    anti-corruption
                    laws, rules or regulations of any jurisdiction, including, but not limited to, the U.S. Foreign Corrupt Practices Act of 1977, as amended (including the rules and regulations thereunder), the UK Bribery Act of
                    2010,
                    as amended (including the rules and regulations thereunder), and any other law, rule or regulation of similar purpose and scope (collectively, "Anti-Corruption Laws"); or (y) any applicable laws, regulations or
                    government guidance regarding anti-money laundering (collectively, <strong>"Anti-Money Laundering Laws"</strong>); (b) has been charged with or convicted of a violation of, or has been or is now a party to any administrative or
                    civil
                    litigation or has been or is now under any administrative, civil or criminal investigation, charge or indictment relating to its compliance with, Anti-Corruption Laws or Anti-Money Laundering Laws, and no such
                    action
                    is threatened; or (c) has been assessed civil or criminal penalties under any Anti-Corruption Laws or Anti-Money Laundering Laws. Company has adopted policies and procedures designed to promote and achieve
                    compliance
                    on the part of Company and all Company Persons with all Anti-Corruption Laws and Anti- Money Laundering Laws.</li>
                  <li>Neither Company / Authorised User nor any Company Person has taken any action in furtherance of an offer, payment, promise to pay or authorization or approval of the payment or giving of money, property, gifts
                    or
                    anything else of value, directly or indirectly, to any government official (including any officer or employee of a government or government-owned or controlled entity or of a public international organization, or
                    any
                    person acting in an official capacity for or on behalf of any of the foregoing, or any political party or party official or candidate for political office) to influence official action or secure an improper
                    advantage
                    or has otherwise made any bribe, payoff, influence payment, kickback or other unlawful payment.</li>
                  <li>Company / Authorised User understands and acknowledges that trades on the QRBCARD Platform may involve a high degree of risk and may constitute speculative activity; Company / Authorised User
                    is
                    experienced in trading on and investing in international commodities, soft commodity, agricultural and similar markets and possesses such knowledge and experience in commercial, trading, financial and business
                    matters as to be capable of evaluating the merits and risks of trading on the QRBCARD Platform; Company is solvent and has the ability to bear the economic risk of trading on the Digital
                    Commodity
                    Exchange Platform, has adequate means of providing for its current and contingent needs, has no need for liquidity with respect to trades on the QRBCARD Platform and is able to sustain a
                    complete
                    loss in connection with its activities on the QRBCARD Platform; and, in availing the QRBCARD Registration Process and accessing the QRBCARD Platform, Company / Authorised User is
                    acting solely for Company's own account as principal and not as proxy or agent for any other person or entity.</li>
                  <li>Company has insurance covering its properties, operations, personnel and businesses, including business interruption insurance, which insurance is in amounts and insures against such losses and risks as are
                    prudent
                    and customary for the businesses in which it is engaged; and neither Company / Authorised User nor any Company Person has any reason to believe that it will not be able to renew its existing insurance coverage as
                    and
                    when such coverage expires or to obtain similar coverage at reasonable cost from similar insurers as may be necessary to continue its business.</li>

                  <p>By ticking the box below, and each time you access the QRBCARD Platform, your Company and you as Authorised User jointly and severally represent and warrant, undertake, consent to, agree and
                    acknowledge pursuant to each of the following additional Warranties:
                  </p>
                </ol>

                <p>By ticking the box below, and each time you access the QRBCARD Platform, your Company and you as Authorised User jointly and severally represent and warrant, undertake, consent to, agree and
                  acknowledge pursuant to each of the following additional Warranties:
                </p>

                <ul>
                  <li>All Company / Authorised User data may be used and disseminated by QRBCARD to the fullest extent permitted under applicable data protection, privacy and other laws and regulations.</li>
                  <li>QRBCARD may conduct investigations into Company / Authorised User and any Company Person, which may include contacting any person referenced in the QRBCARD Registration Process as well as trade
                    references, affiliates, banks and financial institutions, licensing authorities, credit rating agencies, governmental and regulatory authorities and other persons.</li>
                  <li>All information Company / Authorised User provides QRBCARD as part of the QRBCARD Registration Process or otherwise is true, accurate, complete and not misleading in all respects, and all
                    supporting documentation Company / Authorised User provides is genuine and complete;</li>
                  <li>Any untrue, inaccurate, incomplete or misleading submission to QRBCARD may result in a denial of access to the QRBCARD Platform, a referral to governmental or regulatory
                    authorities, legal claims and other sanctions and penalties.</li>
                  <li>Company / Authorised User has an affirmative duty to continually amend and update its registration so as to ensure that all Company / Authorised User information (including these Warranties) and supporting
                    documentation provided to QRBCARD is and remains true, accurate, complete and not misleading in all respects. Company / Authorised User will promptly notify QRBCARD if any such information or
                    supporting documentation is or becomes untrue, inaccurate, incomplete or misleading in any respect.</li>
                  <li>Company / Authorised User will promptly comply in good faith with all QRBCARD requests for additional information and supporting documentation.</li>
                  <li>Company has taken all action (corporate or other) required to be taken for the due authorization of (i) Company / Authorised User to complete the QRBCARD Registration Process; (ii) you to serve as an Authorised
                    User and (iii) the Company / Authorised User accessing the QRBCARD Platform and all Company's / Authorised User's activities thereon. By accessing the QRBCARD Platform,
                    Company and all participating Company Persons are also acting with full apparent authority with respect to all their activities thereon. These Warranties have been duly authorised, executed and delivered by
                    Company / Authorised User.</li>
                  <li>Company / Authorised User has reviewed the Terms in detail and has no reason to believe that it or its registration on the QRBCARD Platform, its accessing the QRBCARD
                    Platform, its activities thereon or its business or operations conflicts with or breaches the Terms in any respect. Company / Authorised User undertakes to abide by the Terms at all times and not to breach the
                    Terms in any respect.</li>
                </ul>
                <p>Confirmed by Authorised User on behalf of itself and the Company
                </p>

              </ng-template>
            </div>

          </mat-tab>

        </mat-tab-group>

        <!-- PREVIOUS & NEXT SECTION BUTTONS -->
        <div fxFlex="100%" fxLayoutAlign="space-between center">
          <div fxLayout="column">
            <a href="javascript:void(0);" (click)="onPreviousTab()" class="dcx-text dcx-blue" >
              <mat-icon class="custom-mat-icon">keyboard_arrow_left</mat-icon><strong>Previous</strong>
            </a>
          </div>
          <div fxLayout="column">
            <a href="javascript:void(0);" (click)="onNextTab()" class="dcx-text dcx-blue">
              <strong>Next<mat-icon class="custom-mat-icon">keyboard_arrow_right</mat-icon></strong>
            </a>
          </div>
        </div>

        <!-- HELP BUTTON -->
        <div fxLayout="row" fxFlex="100%" fxLayoutAlign="end center" class="help-center-container">
          <button mat-raised-button color="accent" class="help-center-btn">
            <mat-icon class="custom-mat-icon">help_outline</mat-icon>
            <a href="mailto:support@dcx.group" class="help-center-link">
              <strong>HELP CENTER</strong>
            </a>
          </button>
        </div>

      </div>

      <div fxHide.lt-md class="vertical-line"></div>

      <!-- DESKTOP table of contents -->
      <div fxHide.lt-md fxFlex.gt-sm="30%" class="tc-desktop-nav-container">

        <ul class="tc-navigation-list">
          <li>
            <h4 class="dcx-text dcx-h4 lightgrey">Table of Contents</h4>
          </li>
          <li *ngFor="let item of pageSections">
            <a routerLink="/legal" class="dcx-text dcx-lighter-blue"
              [class.active]="currentPageSection.fragment==item.fragment"
              [fragment]="item.fragment">{{ item.title }}
            </a>
          </li>
        </ul>

        <div class="support-mail-container">
          <h4 class="dcx-text dcx-h4 lightgrey">Get help at
            <a href="mailto:support@dcx.group" class="dcx-text dcx-lighter-blue">
              <strong>support@dcx.group</strong>
            </a>
          </h4>
        </div>

      </div>

    </mat-drawer-content>

  </mat-drawer-container>

</div>
