<div *ngIf="!isLoading" class="email-unsubscribe" fxLayout="column">

  <div fxLayout="column" fxLayoutAlign="start center">
    <img src="assets/images/corporation/qrbcard_logo_icon.png">
  </div>

  <ng-container *ngIf="unsubscribeErrorMsg; else noErrorTemplate">
    <div fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center">

      <h1 class="dcx-text dcx-h1 dcx-blue georama">
        {{ 'auth.email unsubscribe.unsubscription error' | translate }}
      </h1>

      <span>{{ unsubscribeErrorMsg | translate }}</span>
    </div>
  </ng-container>

  <ng-template #noErrorTemplate>
    <div fxLayout="column" fxLayoutGap="1.0rem" fxLayoutAlign="center center">
      <h1 class="dcx-text dcx-h1 dcx-blue georama">
        {{ 'auth.email unsubscribe.successful unsubscription heading' | translate }}
      </h1>
      <h2 class="dcx-text dcx-h2 georama">
        {{ 'auth.email unsubscribe.successful unsubscription subheading' | translate }}
      </h2>
      <br>
      <div class="auth-contact-finish-message" fxLayout="column">
        <a href="{{ landingUrl }}">
          <button mat-flat-button color="primary">{{ 'auth.contact.go to dcx' | translate }}</button>
        </a>
      </div>
    </div>
  </ng-template>

  <br>

</div>
