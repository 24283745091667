<div class="auth-forgot-container" fxLayout="column" fxLayoutAlign="center center">

  <!-- TITLE -->
  <div fxLayout="column" fxLayoutAlign="center center" class="login-header auth-forgot-logo-container">
    <img src="assets/images/corporation/qrbcard_logo_icon.png">
  </div>
  <h3 class="auth-forgot-title dcx-text dcx-h1"><strong>{{ 'auth.recover password.title' | translate }}</strong></h3>
  <div fxLayout="row">
    <form *ngIf="!isSent" [formGroup]="passwordRecoverForm" fxFlex="320px" fxFlex.xs="100%" fxLayout="column">

      <!-- INITIAL PROCESS: Send code -->
      <div *ngIf="!inFinalProcess" fxLayout="column">
        <div class="auth-forgot-subtitle dcx-text dcx-blue">
          <span>{{ 'auth.recover password.hint' | translate }}</span>
        </div>
        <strong>{{ 'auth.recover password.email' | translate }}</strong>
        <mat-form-field>
          <input type="email" matInput placeholder="{{ 'auth.recover password.email placeholder' | translate }}" formControlName="username">
          <mat-error *ngIf="(passwordRecoverForm.controls.username.errors?.required) && passwordRecoverForm.controls.username.touched">
            <span>{{ 'app.forms.is required' | translate: {item: ('auth.recover password.email' | translate)} }}</span>
          </mat-error>
          <mat-error *ngIf="(passwordRecoverForm.controls.username.errors?.email) && passwordRecoverForm.controls.username.touched">
            <span>{{ 'app.forms.is invalid' | translate: {item: ('auth.recover password.email' | translate)} }}</span>
          </mat-error>
        </mat-form-field>
        <button mat-flat-button color="primary" (click)="requestVerificationCode()" [disabled]="passwordRecoverForm.controls.username.errors?.required || passwordRecoverForm.controls.username.errors?.email" class="button-send-code">
          <span>{{ 'auth.recover password.send code' | translate }}</span>
        </button>
        <mat-error *ngIf="errorMsg">
          <span>{{ errorMsg }}</span>
        </mat-error>
      </div>

      <!-- FINAL PROCESS: Reset credentials -->
      <div *ngIf="inFinalProcess && !isReset" fxLayout="column">
        <strong>
          {{ 'auth.change password.password replace' | translate }}
          <mat-icon inline="true" class="colorInfo" matTooltipPosition="above" matTooltip="{{ 'auth.login.password restriction' | translate }}">
            info_outline
          </mat-icon>
        </strong>
        <mat-form-field>
          <input matInput [type]="hidePassword ? 'password' : 'text'" placeholder="{{ 'auth.change password.password replace' | translate }}" formControlName="password_replace">
          <mat-icon matSuffix (click)="hidePassword = !hidePassword">
            {{ hidePassword ? 'visibility_off' : 'visibility' }}
          </mat-icon>
          <mat-error *ngIf="passwordRecoverForm.controls.password_replace.errors?.required && passwordRecoverForm.controls.password_replace.touched">
            <span>{{ 'app.forms.is required' | translate: {item: ('auth.change password.password replace' | translate)} }}</span>
          </mat-error>
        </mat-form-field>
        <div class="password-hints" fxLayout="column">
          <mat-hint class="password-hints-title">
            <span><strong>{{ 'users.register.password hints title' | translate }}</strong></span>
          </mat-hint>
          <mat-hint>
            <mat-icon class="material-icons-outlined check-icon" *ngIf="!passwordRecoverForm.controls.password_replace.errors?.formatLength && passwordRecoverForm.controls.password_replace.value">done</mat-icon>
            <mat-icon class="material-icons pending-icon" *ngIf="passwordRecoverForm.controls.password_replace.errors?.formatLength || !passwordRecoverForm.controls.password_replace.value">close</mat-icon>
            <span>{{ 'users.register.password length' | translate }}</span>
          </mat-hint>
          <mat-hint>
            <mat-icon class="material-icons-outlined check-icon" *ngIf="!passwordRecoverForm.controls.password_replace.errors?.formatUppercase && passwordRecoverForm.controls.password_replace.value">done</mat-icon>
            <mat-icon class="material-icons pending-icon" *ngIf="passwordRecoverForm.controls.password_replace.errors?.formatUppercase || !passwordRecoverForm.controls.password_replace.value">close</mat-icon>
            <span>{{ 'users.register.password uppercase' | translate }}</span>
          </mat-hint>
          <mat-hint>
            <mat-icon class="material-icons-outlined check-icon" *ngIf="!passwordRecoverForm.controls.password_replace.errors?.formatLowercase && passwordRecoverForm.controls.password_replace.value">done</mat-icon>
            <mat-icon class="material-icons pending-icon" *ngIf="passwordRecoverForm.controls.password_replace.errors?.formatLowercase || !passwordRecoverForm.controls.password_replace.value">close</mat-icon>
            <span>{{ 'users.register.password lowercase' | translate }}</span>
          </mat-hint>
          <mat-hint>
            <mat-icon class="material-icons-outlined check-icon" *ngIf="!passwordRecoverForm.controls.password_replace.errors?.formatNumber && passwordRecoverForm.controls.password_replace.value">done</mat-icon>
            <mat-icon class="material-icons pending-icon" *ngIf="passwordRecoverForm.controls.password_replace.errors?.formatNumber || !passwordRecoverForm.controls.password_replace.value">close</mat-icon>
            <span>{{ 'users.register.password number' | translate }}</span>
          </mat-hint>
          <mat-hint>
            <mat-icon class="material-icons-outlined check-icon" *ngIf="!passwordRecoverForm.controls.password_replace.errors?.formatSpecialCharacter && passwordRecoverForm.controls.password_replace.value">done</mat-icon>
            <mat-icon class="material-icons pending-icon" *ngIf="passwordRecoverForm.controls.password_replace.errors?.formatSpecialCharacter || !passwordRecoverForm.controls.password_replace.value">close</mat-icon>
            <span>{{ 'users.register.password special character' | translate }}</span>
          </mat-hint>
        </div>
        <button mat-raised-button color="primary" (click)="requestChangePassword()" [disabled]="passwordRecoverForm.invalid || errorMsg">
          <span>{{ 'auth.recover password.change password' | translate }}</span>
        </button>
        <mat-error *ngIf="errorMsg">
          <span>{{ 'auth.recover password.invalid link' | translate }}</span>
        </mat-error>
        <button *ngIf="errorMsg" mat-raised-button color="primary" routerLink='/auth/login'>
          <span>{{ 'app.actions.back' | translate }}</span>
        </button>
      </div>
      <div *ngIf="isReset" fxFlex="320px" fxFlex.xs="100%" fxLayout="column">
        <div class="dcx-text dcx-blue" fxLayoutAlign="center center">
          <span>{{ 'auth.change password.password updated correctly' | translate }}</span>
        </div>
        <button mat-raised-button class="auth-recover-back-login-button" color="primary" routerLink='/auth/login'>
          <span>{{ 'app.actions.back to login' | translate }}</span>
        </button>
      </div>
    </form>
    <div *ngIf="isSent" class="auth-forgot-sent-container" fxFlex="320px" fxFlex.xs="100%" fxLayout="column">
      <div fxLayout="column" fxLayoutAlign="center center">
        <img src="assets/images/documents/email.svg">
      </div>
      <div class="dcx-text dcx-blue" fxLayoutAlign="center center">
        <span>{{ 'auth.recover password.check email' | translate }}</span>
      </div>
      <div class="dcx-text" fxLayoutAlign="center center">
        <span><strong>{{ passwordRecoverForm.controls.username.value }}</strong></span>
      </div>
    </div>
  </div>
</div>
