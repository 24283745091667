import { StepperOptions, STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MatPaginatorDefaultOptions, MAT_PAGINATOR_DEFAULT_OPTIONS } from "@angular/material/paginator";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppConstants } from '../app-constants';
// import { MatPaginatorIntl } from '@angular/material/paginator';

export const matStepperSettings: StepperOptions = {
  displayDefaultIndicatorType: false,
  showError: false
}

export const matFormFieldSettings: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  hideRequiredMarker: false,
  floatLabel: 'always'
}

export const matPaginatorSettings: MatPaginatorDefaultOptions = {
  pageSize: AppConstants.DEFAULT_PAGE_SIZE,
  pageSizeOptions: AppConstants.DEFAULT_PAGE_SIZE_OPTIONS,
  // formFieldAppearance: 'outline',
  showFirstLastButtons: true,
  hidePageSize: false
}

export const MATERIAL_SETTINGS = [
  { provide: STEPPER_GLOBAL_OPTIONS, useValue: matStepperSettings },
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormFieldSettings },
  { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: matPaginatorSettings }

]
