import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { AWSAuthService } from '@app/@core/services/aws/auth.service';
import { NotificationService } from '@app/@shared/services/notification.service';

import { PasswordValidator } from '@app/@shared/validators/password-validator';


@Component({
  selector: 'app-password-recover',
  templateUrl: './password-recover.component.html',
  styleUrls: ['./password-recover.component.scss'],
})
export class PasswordRecoverComponent {
  passwordRecoverForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<any>,
    private awsAuthService: AWSAuthService,
    private notificationService: NotificationService) {

    this.createForm();
  }

  private createForm() {
    this.passwordRecoverForm = this.formBuilder.group({
      username: [null, Validators.required],
      verification_code: [null, Validators.required],
      password_replace: [null, [PasswordValidator.password(), Validators.required]]
    });
  }

  requestVerificationCode() {
    this.awsAuthService.forgotPassword(this.passwordRecoverForm.value.username).then(
      (data) => {
        this.notificationService.open(`Verification code sent to ${data.CodeDeliveryDetails.Destination}`);
      })
      .catch((error) => this.notificationService.open(error.message));
  }

  requestChangePassword() {
    this.awsAuthService.forgotPasswordSubmit(
      this.passwordRecoverForm.value.username,
      this.passwordRecoverForm.value.verification_code,
      this.passwordRecoverForm.value.password_replace
    ).then(
      (data) => {
        this.notificationService.open('auth.change password.password updated correctly');
        this.dialogRef.close();
      })
      .catch((error) => this.notificationService.open(error.message));
  }
}
