import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { AuthSessionGuard } from './auth.guard';

import { AuthComponent } from './auth.component';
import { AuthLoginComponent } from './login/auth-login.component';
import { AuthConfirmationComponent } from './confirmation/auth-confirmation.component';
import { AuthForgotComponent } from './forgot/auth-forgot.component';
import { EmailUnsubscribeComponent } from './email-unsubscribe/email-unsubscribe.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';


const routes: Routes = [
  { path: 'auth', component: AuthComponent, children: [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: AuthLoginComponent, data: { title: marker('auth.login.title') }},
    { path: 'confirmation', component: AuthConfirmationComponent, data: { title: marker('auth.account confirmation.title') }},
    { path: 'forgot', component: AuthForgotComponent, data: { title: marker('auth.account forgot.title') }},
  ], canActivate: [AuthSessionGuard]},
  { path: 'legal', component: AuthComponent, children: [
    { path: '', redirectTo: 'terms-and-conditions', pathMatch: 'full' },
    { path: 'terms-and-conditions', component: TermsAndConditionsComponent, data: { title: marker('auth.terms and conditions.title') }},
  ]},
    { path: 'email-unsubscribe', component: AuthComponent, children: [
    { path: '', component: EmailUnsubscribeComponent, data: { title: marker('auth.email unsubscribe.title')} },
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AuthRoutingModule { }
