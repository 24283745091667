import { Injectable } from '@angular/core';
import { Logger } from './logger.service';

import { AWSAuthService } from './aws/auth.service';

const log = new Logger('PermissionService');


interface AccessPermissions {
  email: string;
  sub: string;
}

@Injectable()
export class PermissionService {

  constructor(private awsAuthService: AWSAuthService) { }

  async getRolePermissions(): Promise<AccessPermissions> {
    // TODO: Revise recover permissions from
    // currentAuthenticatedUser > cognitoUser.getSession()

    const session = await this.awsAuthService.currentSession();
    const token = session.getIdToken().decodePayload();


    const access = {
      email: token.email,
      sub: token.sub,
      profile: token.profile ? token.profile : ''
    }

    // Include user info into currentUser object
    this.awsAuthService.currentUser.email = access.email;
    this.awsAuthService.currentUser.sub = access.sub;
    this.awsAuthService.currentUser.profile = access.profile;

    return access as AccessPermissions;
  }

  async checkPermissionRoute(routePath: string): Promise<boolean> {
    const _access: AccessPermissions = await this.getRolePermissions();

    // Check permission modules in user > role
    //const _role_module: Module = _access.role.modules.find(m => routePath.includes(m.path));
    // Check permission modules in user directly
    //const _policy_module: Module = _access.policy.modules.find(m => routePath.includes(m.path));

    //if (_role_module || _policy_module)
      //return true;

    //return false;
    return true;
  }
}
