import { Component, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { PasswordChangeComponent } from '@app/auth/password-change/password-change.component';
import { DialogService } from '@app/@shared/services/dialog.service';



@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent {
  @ViewChild('sidenavPrimary') sidenavPrimary: { toggle: () => void; };

  constructor(
    private media: MediaObserver,
    private dialogService: DialogService
  ) {

   }

  get isMobile(): boolean {
    return this.media.isActive('xs') || this.media.isActive('sm');
  }

  toggleSidenavPrimary() {
    this.sidenavPrimary.toggle();
  }

  profileChangePassword() {
    this.dialogService.open(PasswordChangeComponent, {minWidth: "20vw"});
  }
}
