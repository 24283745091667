<div fxFlex class="shell-container">
  <mat-sidenav-container fxFlex fxFill>
    <!-- SIDENAV PRINCIPAL > Left -->
    <mat-sidenav
      #sidenavPrimary
      [mode]="isMobile ? 'over' : 'side'"
      [opened]="!isMobile"
      [disableClose]="!isMobile"
      ngClass.gt-sm="mat-sidenav-border"
    >
      <app-sidenav-primary></app-sidenav-primary>
    </mat-sidenav>

    <mat-sidenav-content fxFlex>
      <mat-sidenav-container fxFlex>
        <mat-sidenav-content fxFlex>
          <!-- HEADER -->
          <app-header
            [isMobile]="isMobile"
            (toggleSidenavPrimaryEvent)="toggleSidenavPrimary()"
            (profileChangePasswordEvent)="profileChangePassword()"
          >
          </app-header>

          <!-- CONTENT -->
          <div
            class="mat-container content"
            ngClass="content-display-navigation"
            ngClass.sm="content-display"
            ngClass.xs="content-display-mobile"
          >
            <router-outlet></router-outlet>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
