<div
  fxFlex="100%"
  fxLayout="row wrap"
  class="footer-container"
  fxLayoutAlign="center start"
>
  <div fxFlex fxLayout="column" fxLayoutAlign="start center">
    <span fxFlex
      >Copyright © {{ currentYear }} Servicios y Desarrollos Grupo Vermon</span
    >
    <span fxFlex fxLayout="row" fxLayoutGap="0.25rem">
      <a routerLink="/legal">{{
        "users.register.terms and conditions" | translate
      }}</a>
      <span>|</span>
      <a href="{{ landingUrl }}/privacy-policy" target="_blank">{{
        "users.register.privacy policy" | translate
      }}</a>
    </span>
    <span fxFlex class="app-version">webapp version {{ version }}</span>
  </div>
</div>
