import { Component, OnInit, ApplicationRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { merge, interval } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Logger, UntilDestroy, untilDestroyed } from '@core';

import { AppService } from './app.service';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { environment } from '@env/environment';

const log = new Logger('AppComponent');


@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private gtmService: GoogleTagManagerService,
    private appService: AppService) {

    this.appService.runServices();
  }

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    // GTM Receive tag
    this.gtmService.addGtmToDom();

    // Setup Navigation + GTM Monitor Navigation
    this.setupNavigationTitle();
  }


  private setupNavigationTitle() {

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        switchMap(route => route.data),
        untilDestroyed(this)
      )
      .subscribe(event => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(`${this.translateService.instant('app.name')} | ${this.translateService.instant(title)}`);

          // GTM Send navigation
          this.gtmService.pushTag({event: title, pageName: this.router.url});
        }
      });
  }

}
