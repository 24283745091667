import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AWSAuthService } from '@app/@core/services/aws/auth.service';
import { NotificationService } from '@app/@shared/services/notification.service';


@Component({
  selector: 'app-auth-confirmation-account',
  templateUrl: './auth-confirmation-account.component.html',
  styleUrls: ['./auth-confirmation-account.component.scss'],
})
export class AuthConfirmationAccountComponent {
  confirmationAccountForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<any>,
    private awsAuthService: AWSAuthService,
    private notificationService: NotificationService) {

    this.createForm();
  }

  private createForm() {
    this.confirmationAccountForm = this.formBuilder.group({
      username: [this.data.username, Validators.required],
      confirmation_code: [this.data.confirmation_code, Validators.required]
    });

    if (this.confirmationAccountForm.valid)
      this.requestVerificationAccount();
  }

  requestVerificationCode() {
    this.awsAuthService.resendSignUp(this.confirmationAccountForm.value.username).then(
      (data) => {
        this.notificationService.open(`Confirmation code sent to ${data.CodeDeliveryDetails.Destination}`);
      })
      .catch((error) => this.notificationService.open(error.message));
  }

  requestVerificationAccount() {
    this.awsAuthService.confirmSignUp(
      this.confirmationAccountForm.value.username,
      this.confirmationAccountForm.value.confirmation_code
    ).then(
      (data) => {
        this.notificationService.open('auth.account confirmation.confirmation correctly');
        this.dialogRef.close(true);
      })
      .catch((error) => {
        if (error.message.includes('Current status is CONFIRMED'))
          this.dialogRef.close(true);
          
        this.notificationService.open(error.message)
      });
  }
}
