
export interface ModuleInterface {
  label: string;
  icon?: string;
  path?: string;
  sidenav?: boolean;
  expanded?: boolean;
  submodules?: ModuleInterface[];
}


export const modulesSettings: ModuleInterface[] = [


  {
    label: 'company.details.title',
    icon: 'handshake',
    path: '/company',
    sidenav: true
  },
  {
    label: 'employees.title',
    icon: 'handshake',
    path: '/employees',
    sidenav: true
  }
]
