import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { trigger, state, style, animate, transition, AUTO_STYLE } from '@angular/animations';

import { modulesSettings, ModuleInterface } from '@env/modules-settings';
import { filter, take } from 'rxjs/operators';

import { PermissionService } from '@app/@core';

import * as _ from 'lodash';


@Component({
  selector: 'app-sidenav-primary',
  templateUrl: './sidenav-primary.component.html',
  styleUrls: ['./sidenav-primary.component.scss'],
  animations: [
    trigger('collapse', [
      state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('false', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate('250ms ease-in')),
      transition('true => false', animate('250ms ease-out'))
    ])
  ]
})
export class SidenavPrimaryComponent {
  rootPatter: RegExp = /[^/\\]+/;
  modules: ModuleInterface[] = [];

  constructor(public router: Router, private permissionService: PermissionService) {

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    onNavigationEnd.pipe(take(1)).toPromise().then(
      (result: NavigationEnd) => {

        // Auto expand current module
        const _url = result.url.match(this.rootPatter);
        this.checkCurrentModule(_url ? _url[0] : '');

        // Filter modules to view from user role/permissions configuration
        this.checkAccessModules();
      }
    )
  }
  
  checkCurrentModule(root: string) {
    const _module = modulesSettings.find(m => m.path === `/${root}`);
    if (_module)
      _module.expanded = true;
  }

  async checkAccessModules() {
    for await (let m of modulesSettings) {
      let _module: ModuleInterface = _.cloneDeep(m);
      _module.submodules = _module.submodules ? [] : undefined;

      if (m.submodules == undefined) {
        if (await this.permissionService.checkPermissionRoute(m.path))
          this.modules.push(_module);
      }
      else {
        // this.modules.push(m);
        for await (let s of m.submodules) {
            if (await this.permissionService.checkPermissionRoute(s.path))
              _module.submodules.push(s);
        }

        if (_module.submodules.length > 0)
          this.modules.push(_module);
      }
    }
  }
}
