import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  version: string = environment.version;
  landingUrl: string = environment.landingUrl;

  currentYear: number = new Date().getFullYear();

  constructor() { }
}
