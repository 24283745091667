import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { AWSAuthService } from '@app/@core/services/aws/auth.service';

import { PasswordValidator } from '@app/@shared/validators/password-validator';


@Component({
  selector: 'app-auth-forgot',
  templateUrl: './auth-forgot.component.html',
  styleUrls: ['./auth-forgot.component.scss'],
})
export class AuthForgotComponent implements OnInit {
  passwordRecoverForm: FormGroup;

  isSent: Boolean = false;
  isReset: Boolean = false;
  errorMsg: string;
  inFinalProcess: Boolean = false;
  hidePassword: Boolean = true;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private awsAuthService: AWSAuthService) {

    this.createForm();
  }

  ngOnInit(): void {
    const username = this.route.snapshot.queryParamMap.get('username');
    const confirmation_code = this.route.snapshot.queryParamMap.get('confirmation_code');

    if (username && confirmation_code) {
      this.inFinalProcess = true;

      this.passwordRecoverForm.controls.username.setValue(username);
      this.passwordRecoverForm.controls.verification_code.setValue(confirmation_code);

      this.passwordRecoverForm.controls.username.removeValidators(Validators.email);
    }
  }

  private createForm() {
    this.passwordRecoverForm = this.formBuilder.group({
      username: [null, [Validators.email, Validators.required]],
      verification_code: [null, [Validators.required]],
      password_replace: [null, [PasswordValidator.password(), Validators.required]],
    });
  }

  requestVerificationCode() {
    this.passwordRecoverForm.value.username=this.passwordRecoverForm.value.username.toLowerCase();
    this.awsAuthService.forgotPassword(this.passwordRecoverForm.value.username).then(
      (data) => {
        this.isSent = true;
      })
      .catch((error) => {
        this.errorMsg = error.message;
      });
  }

  requestChangePassword() {
    this.awsAuthService.forgotPasswordSubmit(
      this.passwordRecoverForm.value.username,
      this.passwordRecoverForm.value.verification_code,
      this.passwordRecoverForm.value.password_replace
    ).then(
      (data) => {
        this.isReset = true;
      })
      .catch((error) => {
        this.errorMsg = error.message;
      });
  }
}
