import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@app/@core';

import { NotificationService } from '@app/@shared/services/notification.service';

import { environment } from '@env/environment';

@Component({
  selector: 'app-email-unsubscribe',
  templateUrl: './email-unsubscribe.component.html',
  styleUrls: ['./email-unsubscribe.component.scss']
})
export class EmailUnsubscribeComponent implements OnInit {

  log = new Logger(EmailUnsubscribeComponent.name);

  landingUrl: string = environment.landingUrl;

  isLoading: boolean = true;
  unsubscribeErrorMsg: string = null;

  constructor(
    private route: ActivatedRoute,
    private notificationService: NotificationService) {
  }

  ngOnInit() {
    const subscription_reference = this.route.snapshot.queryParamMap.get('reference');

    if (subscription_reference) {
      this.unsubscribe(subscription_reference);
    }
    else {
      this.isLoading = false;
      this.unsubscribeErrorMsg = "auth.email unsubscribe.unsubscribe parameters are not present";
    }
  }

  unsubscribe(subscription_reference: string) {
    

 
  }

}
