export const languageSettings = {
  defaultLanguage: 'es-ES',
  supportedLanguages: [
    {
      code: 'en-US',
      label: 'app.languages.english',
    },
    {
      code: 'es-ES',
      label: 'app.languages.spanish',
    }
  ]
};
