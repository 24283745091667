<div class="auth-confimation-container" fxLayout="column">

  <div fxLayout="column" fxLayoutAlign="start center">
    <img src="assets/images/corporation/qrbcard_logo_icon.png">
  </div>

  <ng-container *ngIf="confirmationErrorMsg; else noErrorTemplate">
    <div fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center">

      <h1 class="dcx-text dcx-h1 dcx-blue georama">
        {{ 'auth.account confirmation.confirmation error' | translate }}
      </h1>

      <span>{{ confirmationErrorMsg | translate }}</span>
    </div>
  </ng-container>

  <ng-template #noErrorTemplate>
    <div fxLayout="column" fxLayoutGap="1.0rem" fxLayoutAlign="center center">

      <h1 class="dcx-text dcx-h1 dcx-blue georama">
        {{ 'auth.account confirmation.successful confirmation heading' | translate }}
      </h1>

      <h2 class="dcx-text dcx-h2 georama">
        {{ 'auth.account confirmation.successful confirmation subheading' | translate }}
      </h2>

      <span>
        {{ confirmationOkMsg | translate }}
      </span>

    </div>
  </ng-template>

  <br>

  <button fxFill mat-flat-button class="dcx-text dcx-white" color="primary" routerLink='/auth/login'>
    <span>{{ 'auth.login.login to platform' | translate }}</span>
  </button>

</div>
