import { Title } from '@angular/platform-browser';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { interval } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@app/@core';

import { TranslateService } from '@ngx-translate/core';

import { AppService } from '@app/app.service';
import { AWSAuthService } from '@app/@core/services/aws/auth.service';


@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() isMobile: Boolean = false;

  @Output() toggleSidenavPrimaryEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() profileChangePasswordEvent: EventEmitter<void> = new EventEmitter<void>();

  loginUser: any;
  notifications: number;

  constructor(
    private titleService: Title,
    private translateService: TranslateService,
    private appService: AppService,
    private awsAuthService: AWSAuthService) { }

  ngOnInit() {
    this.awsAuthService.currentUserInfo().then((user) => {
      this.loginUser = user;
    });

    interval(60 * 1000)
      .pipe(untilDestroyed(this))
      .subscribe(val => {
        this.checkCurrentUserInfo();
      });

  }

  // Check current user info (empty equal to revoke session)
  // -> Auto logout with revoke token
  checkCurrentUserInfo() {
    this.awsAuthService.currentUserInfo().then((user) => {
        this.loginUser = user;

        if (JSON.stringify(user) === '{}') {
          this.logout();
        }
    });
  }

  logout() {
    this.awsAuthService.logout();
  }

  get title() {
    const title = this.titleService.getTitle();
    return title.replace(`${this.translateService.instant('app.name')} | `, '');
  }
}
