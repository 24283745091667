<mat-toolbar class="header-container mat-elevation-z2">

  <button class="menu-button" mat-icon-button (click)="toggleSidenavPrimaryEvent.emit()" fxHide.gt-sm>
    <mat-icon>menu</mat-icon>
  </button>

  <h1 fxHide.xs>{{ title }}</h1>
  <div fxFlex></div>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.25rem">


    <!-- LANGUAGE -->
    <app-language-selector icon="true"></app-language-selector>


    <!-- PROFILE -->
    <div class="header-profile-container">
      <button mat-flat-button [matMenuTriggerFor]="userMenu">
        <div fxLayout="row" fxLayoutGap="0.5rem" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutAlign="center center" class="header-user-profile">
            <mat-icon mat-list-icon>person</mat-icon>
          </div>
          <span *ngIf="!isMobile">{{ loginUser?.attributes.email }}</span>
        </div>
      </button>
    </div>

    <!-- PROFILE > Menu -->
    <div class="header-profile-menu-container">
      <mat-menu #userMenu="matMenu">
        <button mat-menu-item color="primary" [disabled]="true">
          <mat-icon mat-list-icon>account_circle</mat-icon>
          <span>{{ loginUser ? loginUser.attributes.sub : "----- --------" }}</span>
        </button>
        <button mat-menu-item color="primary" [disabled]="true">
          <mat-icon mat-list-icon>mail</mat-icon>
          <span>{{ loginUser ? loginUser.attributes.email : "---------@---" }}</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/profile">
          <mat-icon mat-list-icon>person</mat-icon>
          <span transate>Profile</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logout()">
          <mat-icon mat-list-icon>logout</mat-icon>
          <span transate>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>

</mat-toolbar>
