import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';

import { AuthRoutingModule } from './auth-routing.module';

import { EntityCompanyComponent } from './register/entities/company/entity-company.component';

import { AuthComponent } from './auth.component';
import { AuthLoginComponent } from './login/auth-login.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { PasswordRecoverComponent } from './password-recover/password-recover.component';
import { PasswordRefactorComponent } from './password-refactor/password-refactor.component';
import { AuthConfirmationComponent } from './confirmation/auth-confirmation.component';
import { AuthConfirmationAccountComponent } from './confirmation-account/auth-confirmation-account.component';
import { AuthForgotComponent } from './forgot/auth-forgot.component';
import { EmailUnsubscribeComponent } from './email-unsubscribe/email-unsubscribe.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '@env/environment';


@NgModule({
  imports: [
    AuthRoutingModule,
    SharedModule,

    RecaptchaModule,
    RecaptchaFormsModule
  ],
  declarations: [
    EntityCompanyComponent,
    AuthComponent,
    AuthLoginComponent,
    PasswordChangeComponent,
    PasswordRecoverComponent,
    PasswordRefactorComponent,
    AuthConfirmationComponent,
    AuthForgotComponent,
    AuthConfirmationAccountComponent,
    TermsAndConditionsComponent,
    EmailUnsubscribeComponent
  ],
  providers: [
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.gcp.recaptcha_key } as RecaptchaSettings }
  ]
})
export class AuthModule { }
