<div fxLayout="column" fxFlex class="auth-login-container" ngClass.sm="auth-login-container-lt-sm" ngClass.lt-sm="auth-login-container-lt-sm" ngClass.xs="auth-login-container-lt-sm" ngStyle.lt-md="background-image: url({{this.test[randomNumber]}}); ">
  <div fxLayout="row" fxLayoutAlign="center">
    <div fxLayout="column" fxLayoutAlign="center" fxFlex.gt-xs="400px" ngClass.gt-md="container-login-form" ngClass.md="container-login-form" ngClass.lt-md="container-login-form" ngClass.gt-xs="container-login-form">
      <div>
        <!-- LOGIN FORM -->
        <form [formGroup]="loginForm" fxLayout="column">

          <!-- HEADING -->
          <h2 class="dcx-text dcx-h2 dcx-blue login-heading">
            <span class="bold">{{ 'auth.login.login to your account' | translate }}</span>
          </h2>

          <!-- EMAIL LABEL -->
          <mat-label>
            <span class="bold">{{ 'auth.login.email' | translate }}</span>
          </mat-label>

          <!-- EMAIL INPUT -->
          <mat-form-field fxFlex>
            <input matInput type="email" [placeholder]=" 'auth.login.email' | translate " [formControlName]="loginFormEnum.username" required>
            <mat-error *ngIf="loginForm.controls.username.invalid && loginForm.controls.username.touched">
              <span>{{ 'app.forms.is required' | translate: {item: ('auth.login.email' | translate)} }}</span>
            </mat-error>
          </mat-form-field>

          <!-- PASSWORD LABEL -->
          <mat-label>
            <span class="bold">{{ 'auth.login.password' | translate }}</span>
            <mat-icon inline="true" matTooltip="{{ 'auth.login.password restriction' | translate }}">
              info_outline
            </mat-icon>
          </mat-label>

          <!-- PASSWORD INPUT -->
          <mat-form-field fxFlex>
            <input matInput [type]="hidePassword ? 'password' : 'text'" [placeholder]=" 'auth.login.password' | translate " [formControlName]="loginFormEnum.password" required>
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            <mat-error *ngIf="loginForm.controls.password.invalid && loginForm.controls.password.touched">
              <span>{{ 'app.forms.is required' | translate: {item: ('auth.login.password' | translate)} }}</span>
            </mat-error>
          </mat-form-field>

          <!-- REQUIRED FIELDS + FORGOT PASSWORD LABELS -->
          <div fxFill fxLayout="row wrap" fxLayoutAlign="space-between center">
            <span class="dcx-text lightgrey dcx-h7">
              {{ 'app.forms.required fields' | translate }}
            </span>
            <a mat-button color="primary" class="dcx-text dcx-blue" routerLink="/auth/forgot" [disabled]="progressLogin">
              <h6 class="dcx-text dcx-h6 dcx-blue"><span class="bold">{{ 'auth.login.forgot password' | translate }}</span></h6>
            </a>
          </div>

          <br>

          <!-- BUTTONS (LOGIN, DON'T HAVE AN ACCOUNT, REGISTER) -->
          <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
            <button fxFill mat-flat-button type="submit" class="dcx-text dcx-white" color="primary" (click)="login()" [disabled]="loginForm.invalid || progressLogin">
              <span>{{ 'auth.login.login to platform' | translate }}</span>
            </button>

            <!-- SOCIAL PROVIDERS -->
            <div fxFill fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0.5rem" class="social-prividers">
              <button fxFill mat-stroked-button fxLayout="row" fxLayoutAlign="center center" (click)="loginIdentityFederated('azure')">
                <img src="https://cdn.cdnlogo.com/logos/o/66/office-365.svg"/>
                <span>{{ 'auth.login.login with Office' | translate }}</span>
              </button>
              <mat-error *ngIf="federaSignInError">
                <span>{{ 'auth.login.login with Office failed' | translate }}</span>
              </mat-error>
            </div>


          </div>


          <!-- REMEMBER MY DETAILS ON THIS DEVICE CHECKBOX -->
          <mat-checkbox [formControlName]="loginFormEnum.remember" color="primary" fxLayoutAlign="center center">
            <span class="bold">
              <h4 class="dcx-text dcx-h4">
                {{ 'auth.login.remember' | translate }}
              </h4>
            </span>
          </mat-checkbox>

        </form>

      </div>
    </div>

  </div>

</div>
