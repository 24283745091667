import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AWSAuthService } from '@app/@core/services/aws/auth.service';
import { NotificationService } from '@app/@shared/services/notification.service';

import { PasswordValidator } from '@app/@shared/validators/password-validator';


@Component({
  selector: 'app-password-refactor',
  templateUrl: './password-refactor.component.html',
  styleUrls: ['./password-refactor.component.scss']
})
export class PasswordRefactorComponent {

  passwordRefactorForm: FormGroup;
  hidePassword: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<any>,
    private awsAuthService: AWSAuthService,
    private notificationService: NotificationService) {

    this.createForm();
  }

  private createForm() {
    this.passwordRefactorForm = this.formBuilder.group({
      password_replace: [null, [PasswordValidator.password(), Validators.required]]
    });

  }

  changePassword() {
    this.awsAuthService.completeNewPassword(
      this.data.user,
      this.passwordRefactorForm.value.password_replace
    ).then(
      (result) => {
        this.notificationService.open('auth.refactor password.password configured correctly');
        this.dialogRef.close(result);
      }
    ).catch((error) => this.notificationService.open(error.message));
  }
}
