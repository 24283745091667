<div fxFill fxLayout="column" class="auth-container">

  <div fxFlex="84px" fxLayoutAlign="end center" class="mat-elevation-z2">
    <mat-toolbar fxLayoutAlign="space-between center">

      <img routerLink='/' src="assets/images/corporation/qrbcard_logo.png" />

      <div fxFlex fxLayout="row" fxLayoutAlign="end center">
        <button mat-flat-button color="accent" routerLink='/auth/login' *ngIf="router.url.includes('/auth/register')">
          <strong>{{ 'auth.login.login' | translate | uppercase }}</strong>
        </button>

        <div fxFlex="nogrow" fxLayoutAlign="end start">
          <app-language-selector icon="true"></app-language-selector>
        </div>
      </div>

    </mat-toolbar>
  </div>
  <div fxFlex>
    <router-outlet></router-outlet>
  </div>
</div>
