<div class="password-recover-container">
  <h2 mat-dialog-title>{{ 'auth.recover password.title' | translate }}</h2>
  <form [formGroup]="passwordRecoverForm">
    <mat-dialog-content>
      <div fxLayout="column" fxLayoutGap="1.0rem">
        <mat-form-field>
          <mat-label>{{ 'auth.login.username' | translate }}</mat-label>
          <input matInput placeholder="{{ 'auth.login.username' | translate }}" formControlName="username">
          <mat-hint>{{ 'app.forms.is required' | translate: {item: ('auth.login.username' | translate)} }}</mat-hint>
          <mat-error *ngIf="passwordRecoverForm.controls.username.errors?.required && passwordRecoverForm.controls.username.touched">
            <span>{{ 'app.forms.is required' | translate: {item: ('auth.login.username' | translate)} }}</span>
          </mat-error>
        </mat-form-field>
        <div fxLayout="column" fxLayoutAlign="center center">
          <button fxFill mat-flat-button color="accent" (click)="requestVerificationCode()" [disabled]="passwordRecoverForm.controls.username.errors?.required" class="button-send-code">
            <span>{{ 'auth.recover password.resend code' | translate }}</span>
          </button>
        </div>
        <mat-form-field>
          <mat-label>{{ 'auth.recover password.verification code' | translate }}</mat-label>
          <input matInput placeholder="{{ 'auth.recover password.verification code' | translate }}" formControlName="verification_code">
          <mat-hint>{{ 'app.forms.is required' | translate: {item: ('auth.recover password.verification code' | translate)} }}</mat-hint>
          <mat-error *ngIf="passwordRecoverForm.controls.verification_code.errors?.required && passwordRecoverForm.controls.verification_code.touched">
            <span>{{ 'app.forms.is required' | translate: {item: ('auth.recover password.verification code' | translate)} }}</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'auth.change password.password replace' | translate }}</mat-label>
          <input matInput type="password" placeholder="{{ 'auth.change password.password replace' | translate }}" formControlName="password_replace">
          <mat-hint>{{ 'app.forms.is required' | translate: {item: ('auth.change password.password replace' | translate)} }}</mat-hint>
          <mat-error *ngIf="passwordRecoverForm.controls.password_replace.errors?.required && passwordRecoverForm.controls.password_replace.touched">
            <span>{{ 'app.forms.is required' | translate: {item: ('auth.change password.password replace' | translate)} }}</span>
          </mat-error>
        </mat-form-field>
        <div class="password-hints" fxLayout="column">
          <mat-hint class="password-hints-title">
            <span><strong>{{ 'users.register.password hints title' | translate }}</strong></span>
          </mat-hint>
          <mat-hint>
            <mat-icon class="material-icons-outlined check-icon" *ngIf="!passwordRecoverForm.controls.password_replace.errors?.formatLength && passwordRecoverForm.controls.password_replace.value">done</mat-icon>
            <mat-icon class="material-icons pending-icon" *ngIf="passwordRecoverForm.controls.password_replace.errors?.formatLength || !passwordRecoverForm.controls.password_replace.value">close</mat-icon>
            <span>{{ 'users.register.password length' | translate }}</span>
          </mat-hint>
          <mat-hint>
            <mat-icon class="material-icons-outlined check-icon" *ngIf="!passwordRecoverForm.controls.password_replace.errors?.formatUppercase && passwordRecoverForm.controls.password_replace.value">done</mat-icon>
            <mat-icon class="material-icons pending-icon" *ngIf="passwordRecoverForm.controls.password_replace.errors?.formatUppercase || !passwordRecoverForm.controls.password_replace.value">close</mat-icon>
            <span>{{ 'users.register.password uppercase' | translate }}</span>
          </mat-hint>
          <mat-hint>
            <mat-icon class="material-icons-outlined check-icon" *ngIf="!passwordRecoverForm.controls.password_replace.errors?.formatLowercase && passwordRecoverForm.controls.password_replace.value">done</mat-icon>
            <mat-icon class="material-icons pending-icon" *ngIf="passwordRecoverForm.controls.password_replace.errors?.formatLowercase || !passwordRecoverForm.controls.password_replace.value">close</mat-icon>
            <span>{{ 'users.register.password lowercase' | translate }}</span>
          </mat-hint>
          <mat-hint>
            <mat-icon class="material-icons-outlined check-icon" *ngIf="!passwordRecoverForm.controls.password_replace.errors?.formatNumber && passwordRecoverForm.controls.password_replace.value">done</mat-icon>
            <mat-icon class="material-icons pending-icon" *ngIf="passwordRecoverForm.controls.password_replace.errors?.formatNumber || !passwordRecoverForm.controls.password_replace.value">close</mat-icon>
            <span>{{ 'users.register.password number' | translate }}</span>
          </mat-hint>
          <mat-hint>
            <mat-icon class="material-icons-outlined check-icon" *ngIf="!passwordRecoverForm.controls.password_replace.errors?.formatSpecialCharacter && passwordRecoverForm.controls.password_replace.value">done</mat-icon>
            <mat-icon class="material-icons pending-icon" *ngIf="passwordRecoverForm.controls.password_replace.errors?.formatSpecialCharacter || !passwordRecoverForm.controls.password_replace.value">close</mat-icon>
            <span>{{ 'users.register.password special character' | translate }}</span>
          </mat-hint>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button fxFill mat-raised-button color="primary" (click)="requestChangePassword()" [disabled]="passwordRecoverForm.invalid">
        <span>{{ 'app.actions.accept' | translate }}</span>
      </button>
    </mat-dialog-actions>
    <br>
  </form>
</div>
