import { environment } from '@env/environment';
import { ApplicationRef, Injectable, Injector } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BehaviorSubject, Subscription, interval } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Logger } from './@core';


import { Hub } from '@aws-amplify/core';
import { DeviceUUID } from "device-uuid";

import { AWSAuthService } from './@core/services/aws/auth.service';
import { AWSApiService } from './@core/services/aws/api.service';
import { NotificationService } from './@shared/services/notification.service';


const log = new Logger('AppService');


@Injectable({
  providedIn: 'root'
})
export class AppService {

  private isCookiesConsented: boolean | undefined;
  private subscriptionMessagesNotifications: Subscription;


  constructor(
    // private injector: Injector,
    private appRef: ApplicationRef,
    private bottomSheet: MatBottomSheet,
    private awsAuthService: AWSAuthService,
    private awsApiService: AWSApiService,
    private notificationService: NotificationService) {

    // BUG SAFARI https://github.com/angular/angular/issues/27889
    // if ('Notification' in window) {
    //   log.info("TESTING swPush");
    //   this.swPush = this.injector.get(SwPush, undefined);
    // }

  }

  runServices() {
    log.info("[INIT] Services...");

    // [INIT] Permissions browser tasks
    this.browserMonitoringPermissions();

  }


  // TODO: Control permissions manual actions
  browserMonitoringPermissions() {
    // Check permissions notifications
    if ('permissions' in navigator) {
      navigator.permissions.query({ name: 'notifications' }).then(function (permission) {
        permission.onchange = function () {
          // Check if session
          // const session = await this.awsAuthService.currentSession();
          // const token = session.getIdToken().decodePayload();
          //
          log.info(`[PERMISSION] notifications: ${permission.state}`);
          // if (permission.state != 'denied' && token)
          //   this.appSuscribeMessagesNotifications(token.company_id, token.user_id);
        };
      });
    }
  }

  generateDeviceUUID() {
    const device = new DeviceUUID().parse();

    // TODO: Difference chrome/chromium (uuid is the same for two browsers)

    const deviceAttributes = [
      device.browser, device.platform, device.os, device.cpuCores,
      device.isDesktop, device.isChrome, device.isChromeOS, device.isFirefox,
      device.isSafari, device.isOpera, device.isEdge,
      device.isMobile, device.isTablet, device.isWindows, device.isLinux,
      device.isLinux64, device.isMac, device.isiPad, device.isiPhone,
      device.isKindleFire, device.isiPod, device.isSmartTV, device.pixelDepth
    ];

    return device.hashMD5(deviceAttributes.join(':'));
  }
}
